.member-card {
  width: 20rem;
  padding: 0.15rem 0.5rem;
  margin: 0 0.25rem;

  @media (max-width: 600px) {
    padding: 0.15rem 0.5rem;
    width: 12.5rem;
    height: fit-content;
    margin: 0 0.25rem;
  }
}
.category-description {
  & p {
    margin: 0 !important;
  }
}
.member-drawer {
  width: 30rem;
  padding: 0.15rem 0.5rem;
  margin: 0 0.25rem;

  @media (max-width: 600px) {
    padding: 0.15rem 0.5rem;
    width: 15rem;
    height: fit-content;
    margin: 0 0.25rem;
  }
}

.user-table thead th {
  color: var(--text-color);
  font-weight: 700;
  background-color: var(--header-bg);
  font-size: $font-size-small;
}
.table-row:nth-child(odd) {
  background-color: var(--table-row-odd);
}

.conference {
  background-image: url("/assets/imgs/nsc24/RLP-NSC-background.jpg");
  background-size: cover; /* This will make the image cover the entire element */
  background-position: center; /* This will center the image */
  background-repeat: no-repeat; /* This prevents the image from repeating */
}

.category-page {
  padding: 1.75rem;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  margin-top: calc(#{$navbar-height});
  height: calc(100% - #{$navbar-height});

  @media (max-width: 768px) {
    padding: 1.25rem;
  }
  @media (max-width: 600px) {
    padding: 1rem;
  }
  @media (max-width: 450px) {
    padding: 0.75rem;
  }
  @media (max-width: 350px) {
    padding: 0.5rem;
  }

  .category-page-body {
    width: 100%;
    max-width: 100%;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .main-title {
      padding: 0.75rem;
      margin: 0rem 0rem;
      font-size: $font-size-large;
      @media (max-width: 600px) {
        padding: 0.5rem;
        margin: 0rem;
        font-size: $font-size-large;
      }

      @media (max-width: 350px) {
        padding: 0.25rem;
        margin: 0rem;
        font-size: $font-size;
      }
    }

    .posts-list {
      max-width: 100%;
      width: 100%;
      margin: 1rem 1rem 0 0;
      display: flex;
      flex-direction: column;
      padding: 0 0 0 0;

      &.grid-view {
        max-width: 90%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 1rem;
        justify-content: center;
        align-items: stretch;
      }

      &.list-view {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .app-bar {
    color: var(--text-color);
    background-color: var(--second-navbar-bg);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: calc(#{$navbar-height} + 3rem);
    padding: 0;
    margin: 0;
    font-size: $font-size-large;
    transition: left 0.4s ease-in-out, width 0.4s ease-in-out;
    height: $navbar-height;
    z-index: 9;

    .main-title {
      padding: 0.75rem;
      margin: 0rem 0rem;
      font-size: $font-size-large;

      @media (max-width: 900px) {
        padding: 0.5rem;
        margin: 0rem 0rem;
        font-size: $font-size;
      }

      @media (max-width: 800px) {
        padding: 0.25rem;
        margin: 0rem 0rem;
        font-size: calc(0.01rem + #{$font-size-small});
        text-align: left;
      }

      @media (max-width: 350px) {
        padding: 0.1rem;
        text-align: left;
        margin: 0.5rem 0rem;
        font-size: calc(0.01rem + #{$font-size-smallest});
      }
    }

    .app-bar-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0rem;
      margin: 0rem;
      gap: 1rem;

      .sorting-select {
        height: fit-content;
        padding: 0;
        margin: 0 0 0 0;

        .MuiSelect-select {
          padding: 0.5rem 1.75rem;
          font-size: $font-size;

          @media (max-width: 768px) {
            padding: 0.5rem 1.25rem;
            font-size: 0.75rem;
          }

          @media (max-width: 600px) {
            padding: 0.5rem 1rem;
            font-size: 0.75rem;
          }

          @media (max-width: 350px) {
            padding: 0.25rem 0 0.25rem 0;
            font-size: 0.5rem;
          }
        }
        .MuiSvgIcon-root {
          padding: 0;
          margin: 0;
          right: 0.5rem;

          @media (max-width: 600px) {
            right: 0.15rem;
          }
        }

        .sorting-select-item {
          height: fit-content;
          padding: 0;
          margin: 0;
        }
      }

      .btn {
        &.btn-create {
          background-color: var(--nav-btn-create-color);
          color: var(--nav-btn-create-text);
          font-weight: 700;
          padding: 0.5rem 2rem;
          width: fit-content;
          height: fit-content;
          margin: 0 0.25rem 0 0;

          @media (max-width: 768px) {
            font-size: 0.65rem;
          }

          @media (max-width: 600px) {
            padding: 0.75rem 0.75rem;
            width: fit-content;
            height: fit-content;
            margin: 0 0.15rem;
            font-size: 0.65rem;
          }
          @media (max-width: 350px) {
            padding: 0.5rem 0.5rem;
            width: fit-content;
            height: fit-content;
            margin: 0 0.05rem 0 0.05rem;
            font-size: 0.5rem;
          }
        }
      }
      .btn-text {
        font-size: $font-size;
        margin-left: 0.75rem;
      }
      @media (max-width: 768px) {
        gap: 0.1rem;
      }
    }
    @media (max-width: 768px) {
      font-size: $font-size;
    }

    @media (max-width: 600px) {
      left: $sidebar-width-small;
      width: calc(100% - #{$sidebar-width-small});
      font-size: $font-size-small;
      height: $navbar-height;
    }
    &.sidebar-open {
      left: $sidebar-width;
      width: calc(100% - #{$sidebar-width});
    }
    &.sidebar-closed {
      left: $sidebar-width-small;
      width: calc(100% - #{$sidebar-width-small});
    }
  }

  &.chat-category {
    height: calc(100%);
    width: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
    transition: width 0.7s ease-in-out;

    .chat-page-body {
      height: calc(100% - #{$navbar-height} - 1rem);
      width: 100%;
      padding: 0;
      margin: 0;
      margin-top: calc(#{$navbar-height} + 0.5rem);
      display: flex;
      flex-direction: row;
      transition: width 0.7s ease-in-out;
      position: relative;

      .chat-members-list {
        height: calc(100% - #{$navbar-height} - 3rem);
        background-color: var(--second-navbar-bg);
        max-width: fit-content;
        padding: 0;
        margin: 0;
        width: 0;
        overflow: hidden;
        transition: width 0.8s ease-in-out;
        border-left: 0.1rem solid #ccc;
        padding-left: 0.5rem;

        &.visible {
          transition: width 0.7s ease-in-out;
          overflow-y: auto;
          width: 100%;
          max-width: fit-content;

          @media (min-width: 968px) {
            max-width: 25rem;
          }
          @media (min-width: 1468px) {
            max-width: 30rem;
          }
        }
      }
    }
  }
  &.floor-schedule {
    padding: 0;
    margin: 0;
    margin-top: calc(#{$navbar-height});
    height: calc(100% - #{$navbar-height});
  }
  &.listing-category {
    padding: 0;
    margin: 0;
    margin-top: calc(#{$navbar-height});
    height: calc(100% - #{$navbar-height});
  }
}

.label {
  font-weight: bold;
  background-color: var(--bg-color); /* Light gray background */
  padding: 4px; /* Add some padding for better readability */
}

.table-row {
  border-bottom: 1px solid var(--second-navbar-bg); /* Add bottom border to each table row */
}

.user-table {
  // border-collapse: collapse; /* Collapse table borders */
  border: 2px var(--bg-color) solid;
}

.card {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add drop shadow to the card */
}
