.chat-window {
  width: 30rem;
  display: flex;
  flex-direction: column;
  min-height: 25rem;
  max-height: 45rem;
  font-size: $font-size;
  overflow: hidden;

  @media (max-width: 600px) {
    width: 19rem;
    min-height: 15rem;
    max-height: 35rem;
    font-size: $font-size-small;
  }

  @media (max-width: 350px) {
    width: 13rem;
    min-height: 15rem;
    max-height: 35rem;
    font-size: $font-size-small;
  }

  .chat-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 3rem;
    background-color: var(--navbar-bg);
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 1rem 0.5rem;
    margin: 0;
    font-size: $font-size;

    @media (max-width: 600px) {
      min-height: 2rem;
      font-size: $font-size-small;
    }
    .btn {
      &.btn-create {
        background-color: var(--nav-btn-create-color);
        color: var(--nav-btn-create-text);
        color: var(--text-color);
        padding: 0.5rem 2rem;
        width: fit-content;
        height: fit-content;
        margin: 0 0.25rem 0 0;

        @media (max-width: 600px) {
          padding: 0.15rem 1rem;
          width: fit-content;
          height: fit-content;
          margin: 0 0.5rem 0 0.5rem;
        }
      }
    }
  }

  .chat-body {
    display: flex;
    flex-direction: column;
    padding: 0;
    flex-grow: 1;
    height: 100%;
    overflow: hidden;

    .chat-list {
      margin: 0.5rem 0;
      padding: 0.5rem 0.5rem;
      overflow: auto;

      .chat-item {
        display: flex;
        flex-direction: row;
        justify-content: baseline;
        align-items: stretch;
        margin: 0.5rem 0;
        padding: 0.5rem 0.5rem;
        border-radius: 10px;
        border: 1px solid #a0a0a0;

        .MuiIconButton-root {
          padding: 0;
        }

        .chat-card-header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .chat-card-header-title {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: stretch;
            margin-right: 1rem;
            width: 100%;
            .chat-card-header-title-badge {
              margin-left: 1.5rem;
              @media (max-width: 600px) {
                margin-left: 0;
              }
            }
          }
        }
        .chat-menu-button {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: baseline;
          padding: 0 0.5rem;
          margin: 0;
          width: fit-content;
          height: fit-content;

          .MuiIconButton-root {
            padding: 0;
          }
        }
        .delete-chat-button {
          padding: 0;
          margin: 0;
        }
      }
      .chat-item-unread {
        background-color: var(--chat-item-unread);
      }

      .chat-item-read {
        background-color: var(--chat-item-read);
      }
    }
  }
  .message-container {
    overflow-y: auto;
    padding: 0.5rem;
    position: relative;

    .loading-spinner {
      display: flex;
      justify-content: center;
      padding: 1rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transition: opacity 0.3s ease-in-out;
      opacity: 1;

      &.visible {
        opacity: 1;
      }
    }
  }

  .message-block {
    .date-header {
      text-align: center;
      margin-top: 10px;
      margin-bottom: 10px;
      color: #666;
      font-size: 0.9em;
    }

    &-body {
      max-width: 20rem;
      min-width: 13rem;
      padding: 0.25rem 0.4rem;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      &.message-block-body-sender {
        color: var(--message-block-text-color-sender);
        background-color: var(--message-block-item-sender);
      }
      &.message-block-body-receiver {
        background-color: var(--message-block-item-receiver);
        color: var(--message-block-text-color-receiver);
      }

      @media (max-width: 600px) {
        max-width: 15rem;
        min-width: 7rem;
      }
      .message-block-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .message-block-time {
          font-size: 0.65rem;
          @media (max-width: 600px) {
            font-size: 0.5rem;
          }
        }
        .message-block-initials {
          background-color: var(--message-block-item-initials);
          width: fit-content;
          padding: 0.275rem;
          border-radius: 50%;
        }
      }
    }

    &-left {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 10px;
    }

    &-right {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }
  .message-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    left: 0;
    background-color: var(--message-input-bg);
    padding: 0.5rem;
    border-top: 1px solid #ccc;
    z-index: 10;
    padding: 0;
    margin: 0;
    width: 100%;

    .message-input-textarea {
      position: relative;
      margin: 0;
      padding: 0;
    }

    .message-input-button {
      position: absolute;
      padding: 0.5rem 0.8rem;
      right: 1%;
      bottom: 4%;
      z-index: 2;
    }
  }
}
