// ProductList.scss

.product-list {
  .MuiPaper-root {
    padding: 16px; // Add padding to Paper component
  }

  .MuiTextField-root {
    margin-bottom: 16px; // Add margin below the TextField
  }

  .MuiTableContainer-root {
    margin-bottom: 16px; // Add margin below the TableContainer
  }

  .MuiTableHead-root {
    background-color: #f4f4f4; // Light background for table headers
    border-bottom: 1px solid #ddd; // Border below table headers
  }

  .MuiTableCell-root {
    border: 0.5px solid #ccc; // Add thin border to table cells
  }

  .MuiTableSortLabel-root {
    color: #004aad; // Update sort label color
  }

  .MuiTablePagination-root {
    border-top: 1px solid #ddd; // Add border on top of pagination
  }
}

.subscription-list {
  .MuiPaper-root {
    padding: 16px; // Add padding to Paper component
  }

  .MuiTextField-root {
    margin-bottom: 16px; // Add margin below the TextField
  }

  .MuiTableContainer-root {
    margin-bottom: 16px; // Add margin below the TableContainer
  }

  .MuiTableHead-root {
    background-color: #f4f4f4; // Light background for table headers
    border-bottom: 1px solid #ddd; // Border below table headers
  }

  .MuiTableCell-root {
    border: 0.5px solid #ccc; // Add thin border to table cells
  }

  .MuiTableSortLabel-root {
    color: #004aad; // Update sort label color
  }

  .MuiTablePagination-root {
    border-top: 1px solid #ddd; // Add border on top of pagination
  }
}

.transaction-list {
  .MuiPaper-root {
    padding: 16px; // Add padding to Paper component
  }

  .MuiTextField-root {
    margin-bottom: 16px; // Add margin below the TextField
  }

  .MuiTableContainer-root {
    margin-bottom: 16px; // Add margin below the TableContainer
  }

  .MuiTableHead-root {
    background-color: #f4f4f4; // Light background for table headers
    border-bottom: 1px solid #ddd; // Border below table headers
  }

  .MuiTableCell-root {
    border: 0.5px solid #ccc; // Add thin border to table cells
  }

  .MuiTableSortLabel-root {
    color: #004aad; // Update sort label color
  }

  .MuiTablePagination-root {
    border-top: 1px solid #ddd; // Add border on top of pagination
  }
}

.invoice-list {
  .MuiPaper-root {
    padding: 16px; // Add padding to Paper component
  }

  .MuiTextField-root {
    margin-bottom: 16px; // Add margin below the TextField
  }

  .MuiTableContainer-root {
    margin-bottom: 16px; // Add margin below the TableContainer
  }

  .MuiTableHead-root {
    background-color: #f4f4f4; // Light background for table headers
    border-bottom: 1px solid #ddd; // Border below table headers
  }

  .MuiTableCell-root {
    border: 0.5px solid #ccc; // Add thin border to table cells
  }

  .MuiTableSortLabel-root {
    color: #004aad; // Update sort label color
  }

  .MuiTablePagination-root {
    border-top: 1px solid #ddd; // Add border on top of pagination
  }
}
.billing-page {
  padding: 20px;
  background-color: #f5f5f5; // Light background color for better contrast

  &__heading {
  
    // Default heading styles
    font-size: 2rem; // Adjust as needed
    font-weight: bold;
    margin-bottom: 1rem; // Adjust spacing
  }

  &__heading--h1 {
    font-size: 2.5rem; // Adjust size for h1
  }

  &__heading--h2 {
    font-size: 2rem; // Adjust size for h2
  }

  // Add styles for other heading levels as needed
}

// Paper components styling
.billing-page__section {
  padding: 20px;
  margin-bottom: 20px;
  background-color: #ffffff; // White background for the sections
  border-radius: 8px; // Rounded corners
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for elevation
}

// Buttons
.billing-page__button {
  margin: 0 5px;
  padding: 10px;
  border-radius: 4px;
  background-color: #007bff; // Primary color for buttons
  color: #ffffff; // White text color
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3; // Darker color on hover
  }
}

// Tooltips
.billing-page__tooltip {
  margin-top: 10px;
}
