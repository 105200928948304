a:-webkit-any-link {
  color: var(--hyperlink);
  cursor: pointer;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.post-page.conference {
  background-image: url("/assets/imgs/nsc24/RLP-NSC-background.jpg");
  background-size: contain; /* This will make the image cover the entire element */
  background-position: center top; /* This will center the image */
  background-repeat: no-repeat; /* This prevents the image from repeating */
}

.post-page {
  width: 100%;
  margin: 0;
  margin-bottom: 5rem; // add margin to bottom to see bottom of card - must be after margin:0
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .app-bar {
    color: var(--text-color);
    background-color: var(--second-navbar-bg);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: calc(#{$navbar-height} + 3rem);
    padding: 0;
    margin: 0;
    font-size: $font-size-large;
    transition: left 0.4s ease-in-out, width 0.4s ease-in-out;
    height: $navbar-height;
    z-index: 9;

    .main-title {
      padding: 0.75rem;
      margin: 0rem 0rem;
      font-size: $font-size-large;

      @media (max-width: 768px) {
        padding: 0.5rem;
        margin: 0.5rem 0rem;
        font-size: $font-size;
      }

      @media (max-width: 600px) {
        padding: 0.5rem;
        margin: 0.5rem 0rem;
        font-size: calc(0.01rem + #{$font-size});
      }

      @media (max-width: 350px) {
        padding: 0.1rem;
        margin: 0.5rem 0rem;
        font-size: calc(0.01rem + #{$font-size-small});
      }
    }

    .app-bar-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0rem;
      margin: 0rem;

      .sorting-select {
        height: fit-content;
        padding: 0;
        margin: 0 1rem 0 0;

        .MuiSelect-select {
          padding: 0.5rem 1.75rem;
          font-size: $font-size;

          @media (max-width: 768px) {
            padding: 0.5rem 1.25rem;
            font-size: 0.75rem;
          }

          @media (max-width: 600px) {
            padding: 0.5rem 1rem;
            font-size: 0.75rem;
          }

          @media (max-width: 350px) {
            padding: 0.25rem 0 0.25rem 0;
            font-size: 0.5rem;
          }
        }
        .MuiSvgIcon-root {
          padding: 0;
          margin: 0;
          right: 0.5rem;

          @media (max-width: 600px) {
            right: 0.15rem;
          }
        }

        .sorting-select-item {
          height: fit-content;
          padding: 0;
          margin: 0;
        }
      }
      .btn {
        &.btn-create {
          background-color: var(--nav-btn-create-color);
          color: var(--nav-btn-create-text);
          color: var(--text-color);
          padding: 0.5rem 2rem;
          width: fit-content;
          height: fit-content;
          margin: 0 0.25rem 0 0;

          @media (max-width: 768px) {
            font-size: 0.65rem;
          }

          @media (max-width: 600px) {
            padding: 0.75rem 0.75rem;
            width: fit-content;
            height: fit-content;
            margin: 0 0.15rem;
            font-size: 0.65rem;
          }
          @media (max-width: 350px) {
            padding: 0.5rem 0.5rem;
            width: fit-content;
            height: fit-content;
            margin: 0 0.05rem 0 0.05rem;
            font-size: 0.5rem;
          }
        }
      }
      .btn-text {
        font-size: $font-size;
        margin-left: 0.75rem;
      }
    }
    @media (max-width: 768px) {
      font-size: $font-size;
    }

    @media (max-width: 600px) {
      left: $sidebar-width-small;
      width: calc(100% - #{$sidebar-width-small});
      font-size: $font-size-small;
    }
    &.sidebar-open {
      left: $sidebar-width;
      width: calc(100% - #{$sidebar-width});
    }
    &.sidebar-closed {
      left: $sidebar-width-small;
      width: calc(100% - #{$sidebar-width-small});
    }
  }

  &-body {
    width: 100%;
    max-width: 55rem;
    height: calc(100% - #{$navbar-height});
    margin: 0;
    padding: 0;
    margin-top: calc(#{$navbar-height} + 1rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.post-card {
  &.list-view {
    position: relative;
    width: 100%;
    border: 0.5px solid #666;
    padding-bottom: 20px;

    .coming-soon-banner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: rgba(
        255,
        165,
        0,
        0.75
      ); /* Orange with some transparency */
      color: white;
      text-align: center;
      padding: 5px 0;
      font-size: 16px;
      font-weight: bold;
      z-index: 10;
    }

    &.margin-bottom {
      margin-bottom: 1rem;
    }

    .aspect-ratio-16-9 {
      position: relative;
      width: 100%;
      padding-top: 56.25%;
      overflow: hidden;
    }

    .aspect-ratio-content {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .post-content-image {
      max-width: 100%;
      height: auto;
    }

    .post-card-header {
      font-size: $font-size;
      .card-header-title {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: $font-size;
      }

      @media (max-width: 600px) {
        font-size: $font-size-small;
      }

      @media (max-width: 350px) {
        font-size: $font-size-small;
      }

      &-date {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--no-sidebar-bg);
        padding: 0.25rem 0.75rem;
        border-radius: 5px;
        border: 1px solid var(--nav-btn-link-text-color);

        &-day {
          font-size: $font-size-large;
          @media (max-width: 600px) {
            font-size: $font-size;
          }
          @media (max-width: 450px) {
            font-size: $font-size-small;
          }
          @media (max-width: 350px) {
            font-size: $font-size-smallest;
          }
        }
        &-month {
          font-size: $font-size;
          @media (max-width: 600px) {
            font-size: $font-size-small;
          }
          @media (max-width: 450px) {
            font-size: $font-size-smallest;
          }
        }

        &-local {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;

          &-event-type-item {
            padding: 0.25rem 0.75rem;
            margin: 0.25rem 0.75rem;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            @media (max-width: 600px) {
              padding: 0.15rem 0.5rem;
              margin: 0.15rem 0.5rem;
            }
            @media (max-width: 450px) {
              padding: 0.05rem 0.35rem;
              margin: 0.05rem 0.35rem;
            }
          }
          &-small {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            font-size: $font-size;
            @media (max-width: 600px) {
              font-size: $font-size-small;
            }
            @media (max-width: 450px) {
              font-size: $font-size-smallest;
            }
          }
        }
      }
      .avatar {
        cursor: pointer;
      }

      .avatar-name {
        cursor: pointer;
        text-decoration: none;
        color: var(--nav-btn-link-color);
        font-size: $font-size;
        @media (max-width: 600px) {
          font-size: $font-size;
        }

        @media (max-width: 350px) {
          font-size: $font-size-small;
        }
      }

      .category-text {
        padding-left: 0.5rem;
        font-size: $post-card-header-font-size;
        @media (max-width: 600px) {
          padding-left: 0.35rem;
          font-size: $post-card-header-font-size;
        }

        @media (max-width: 350px) {
          padding-left: 0.25rem;
          font-size: $post-card-header-font-size-small;
        }
      }

      .date-text {
        font-size: $post-card-header-font-size;
        @media (max-width: 600px) {
          font-size: $post-card-header-font-size;
        }

        @media (max-width: 350px) {
          font-size: $post-card-header-font-size-small;
        }
      }
    }

    .post-card-title-wrap {
      width: 100%;
      display: flex;
      gap: 1rem;
      justify-content: space-between;

      .post-card-title {
        width: 100%;
        font-size: $post-card-title-font-size-large;
        font-weight: 800;
        margin-bottom: 0.75rem;
        padding-bottom: 0rem;
        flex: 75%;

        @media (max-width: 600px) {
          font-size: $post-card-title-font-size;
          font-weight: 700;
        }

        @media (max-width: 350px) {
          font-weight: 450;
          font-size: $post-card-title-font-size-small;
        }
      }

      .post-card-view-badge {
        flex: 25%;
      }
    }
    .custom-card-content {
      padding-top: 0;
      margin-top: 0;

      .likes-block {
        .likes-block-avatars {
          .likes-avatars {
            display: flex;
            flex-direction: row;
            padding: 0.5rem 0.5rem;
          }
        }
      }
    }

    .post-card-address {
      font-size: $font-size-largest;
      font-weight: 700;
    }
    .post-card-listPrice {
      font-size: $font-size-large;
      font-weight: 700;
      color: green;
    }
    .post-card-listing {
      &-title {
        font-size: $post-card-title-font-size;
        font-weight: 600;
      }
    }

    .listing-custom-card-content {
      padding: 10px;
      margin-top: 0;
      border: none;
      background-color: var(--listing-custom-card-bg);

      .likes-block {
        .likes-block-avatars {
          .likes-avatars {
            display: flex;
            flex-direction: row;
            padding: 0.5rem 0.5rem;
          }
        }
      }
    }

    .post-card-tier-label {
      margin: 0;
      padding: 0.5rem 1rem;
      font-size: 0.75rem;
      width: 100%;
      text-align: center;
      font-weight: bold;
      color: var(--post-card-tier-office-label-color);
      background-color: var(--post-card-tier-office-label-bg);
    }
    .post-card-new-post-label {
      margin: 0;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      width: 100%;
      text-align: center;
      font-weight: bold;
      color: var(--post-card-new-post-label-color);
      background-color: var(--post-card-new-post-label-bg);
    }
  }
  &.grid-view {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    height: 100%;

    .post-card-title {
      font-size: 1rem;
      font-weight: bold;
    }

    .custom-card-content {
      padding: 0.5rem;
    }

    .post-card-actions-block {
      display: none;
    }

    .coming-soon-banner {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background-color: rgba(255, 165, 0, 0.75);
      color: white;
      text-align: center;
      padding: 5px 0;
      font-size: 16px;
      font-weight: bold;
      z-index: 10;
    }
    .post-card-tier-label {
      margin: 0;
      padding: 0.5rem 1rem;
      font-size: 0.75rem;
      width: 100%;
      text-align: center;
      font-weight: bold;
      color: var(--post-card-tier-office-label-color);
      background-color: var(--post-card-tier-office-label-bg);
    }
    .post-card-new-post-label {
      margin: 0;
      padding: 0.5rem 1rem;
      font-size: 1rem;
      width: 100%;
      text-align: center;
      font-weight: bold;
      color: var(--post-card-new-post-label-color);
      background-color: var(--post-card-new-post-label-bg);
    }
  }
}

.open-button-mode {
  width: 100%; /* Ensures the div takes the full width */
}

/* Button styling within widgets */
.open-button-mode button {
  background-color: #4caf50;
  color: var(--text-color);
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.open-button-mode:hover {
  background-color: #adefad;
  color: var(--nav-btn-link-text-color);
}

.post-content {
  margin: 0px;
  padding: 20px;
  border-radius: 5px;
  font-weight: 300;
  color: var(--text-color) !important;
  // background-color: var(--bg-color-opt2);
  .replaced-link {
    color: var(--text-color) !important;
  }
  p,
  p br {
    margin: 0;
    padding: 0;
  }
}

.theme-dark .post-content span {
  color: var(--text-color) !important;
}

.post-comment-card {
  padding: 0;
  margin: 0;
  font-size: $font-size-large;

  @media (max-width: 600px) {
    font-size: $font-size;
  }

  @media (max-width: 350px) {
    font-size: $font-size-small;
  }

  .post-comment-card-header {
    .avatar {
      cursor: pointer;
    }
    .comment-header-block {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .avatar-name {
      cursor: pointer;
      text-decoration: none;
      color: var(--nav-btn-link-color);
      font-size: $font-size;
      @media (max-width: 600px) {
        font-size: $font-size;
      }

      @media (max-width: 350px) {
        font-size: $font-size-small;
      }
    }
    .additional-text {
      padding-left: 0.5rem;
      font-size: $post-card-header-font-size;
      @media (max-width: 600px) {
        padding-left: 0.35rem;
        font-size: $post-card-header-font-size;
      }

      @media (max-width: 350px) {
        padding-left: 0.25rem;
        font-size: $post-card-header-font-size-small;
      }
    }
    .date-text {
      font-size: $post-card-header-font-size;
      @media (max-width: 600px) {
        font-size: $post-card-header-font-size;
      }

      @media (max-width: 350px) {
        font-size: $post-card-header-font-size-small;
      }
    }
  }
  .comment-content {
    padding: 0 1rem;
    margin: 0;
    font-size: $font-size-large;

    @media (max-width: 600px) {
      padding: 0 0.8rem;
      font-size: $font-size;
    }

    @media (max-width: 350px) {
      padding: 0 0.5rem;
      font-size: $font-size-small;
    }
    .replaced-link {
      font-size: $font-size;
      @media (max-width: 600px) {
        font-size: $font-size;
      }

      @media (max-width: 350px) {
        font-size: $font-size-small;
      }
    }
  }

  .reply-content {
    padding: 0 1rem 0 1rem;
    margin: 0;
    font-size: $font-size-large;

    @media (max-width: 600px) {
      padding: 0 0.8rem;
      font-size: $font-size;
    }

    @media (max-width: 350px) {
      padding: 0 0.5rem;
      font-size: $font-size-small;
    }
    .replaced-link {
      font-size: $font-size-large;
      @media (max-width: 600px) {
        font-size: $font-size;
      }

      @media (max-width: 350px) {
        font-size: $font-size-small;
      }
    }
  }

  .comment-card-actions-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    .comment-card-actions {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .replies-block {
        .replies-block-span {
          padding: 0 1rem 0 0;
          font-size: $font-size;
          @media (max-width: 600px) {
            padding: 0 0.65rem 0 0;
            font-size: $font-size;
          }

          @media (max-width: 350px) {
            padding: 0 0.25rem 0 0;
            font-size: $font-size-small;
          }
        }
      }
    }
  }

  &.reply-card {
    padding-left: 2.5rem;

    @media (max-width: 600px) {
      padding-left: 1.5rem;
    }

    @media (max-width: 350px) {
      padding-left: 0.5rem;
    }
  }
}

.create-post {
  z-index: 9999999999999999999999999999999999;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;

  .create-post-form {
    width: 100%;
    max-width: 100%;
  }

  .MuiDialog-container {
    width: 100%;
    max-width: 100%;
    position: fixed;
    top: 0;
    left: 5%;
    @media (max-width: 1000px) {
      width: 100%;
      left: 12.5%;
    }
    @media (max-width: 850px) {
      width: 60%;
      left: 33%;
    }
    @media (max-width: 600px) {
      width: 95%;
      left: 7%;
    }
  }
  .dialog-title {
    font-size: 2rem;
    background-color: var(--dialog-title-color);
    color: white;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
  }

  .image-upload-container {
    display: none;

    &.active {
      display: block;
      margin-top: 1rem;
    }

    .crop-container {
      max-width: 100%;
      height: 20rem;
      position: relative;
      border: 1px solid #ddd;
      margin-bottom: 1rem;

      canvas {
        max-width: 100%;
      }
    }

    .controls {
      display: flex;
      justify-content: center;
      padding: 0.5rem;

      .zoom-range {
        width: 80%;
        margin: 0 auto;
      }
    }
  }

  .record-view-container {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    .webcam {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}

.edit-post {
  z-index: 9999999999999999999999999999999999;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;

  .edit-post-form {
    width: 100%;
    max-width: 100%;
  }

  .MuiDialog-container {
    width: 100%;
    max-width: 100%;
    position: fixed;
    top: 0;
    left: 5%;
    @media (max-width: 1000px) {
      width: 100%;
      left: 12.5%;
    }
    @media (max-width: 850px) {
      width: 60%;
      left: 33%;
    }
    @media (max-width: 600px) {
      width: 95%;
      left: 7%;
    }
  }
  .dialog-title {
    font-size: 2rem;
    background-color: var(--dialog-title-color);
    color: white;
    font-weight: bold;
    padding: 10px;
    border-radius: 5px;
  }

  .image-upload-container {
    display: none;

    &.active {
      display: block;
      margin-top: 1rem;
    }

    .crop-container {
      max-width: 100%;
      height: 20rem;
      position: relative;
      border: 1px solid #ddd;
      margin-bottom: 1rem;

      canvas {
        max-width: 100%;
      }
    }

    .controls {
      display: flex;
      justify-content: center;
      padding: 0.5rem;

      .zoom-range {
        width: 80%;
        margin: 0 auto;
      }
    }
  }

  .sub-items-list {
    display: flex;
    flex-direction: column;
    width: 100%;
    .sub-list-item {
      display: flex;
      flex-direction: row;
    }
  }
  .record-view-container {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;

    .webcam {
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}

.edit-comment {
  z-index: 9999999999999999999999999999999999;
  display: flex;
  flex-direction: column;
  width: 100%;

  .MuiDialog-container {
    width: 100%;
    position: fixed;
    top: 0;
    left: 5%;
    @media (max-width: 1000px) {
      width: 100%;
      left: 12.5%;
    }
    @media (max-width: 850px) {
      width: 60%;
      left: 33%;
    }
    @media (max-width: 600px) {
      width: 95%;
      left: 7%;
    }
  }
  .dialog-title {
    font-size: 2rem;
  }
}

.post-card-actions-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  .post-card-actions {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .comments-block {
      .comments-block-span {
        padding: 0 1rem 0 0;
        font-size: $font-size;
        @media (max-width: 600px) {
          padding: 0 0.65rem 0 0;
          font-size: $font-size;
        }

        @media (max-width: 350px) {
          padding: 0 0.25rem 0 0;
          font-size: $font-size-small;
        }
      }
    }
  }
}

.post-comment-block {
  width: 100%;
  padding: 0.25rem 0.5rem 0.5rem 0.5rem;
  display: flex;
  flex-direction: row;

  .comment-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    left: 0;
    background-color: var(--message-input-bg);
    padding: 0.5rem;
    border-top: 1px solid #707070;
    z-index: 10;
    padding: 0;
    margin: 0 0 0 1rem;
    width: 100%;
    margin-bottom: 50px;

    .comment-input-textarea {
      flex-grow: 1;
      min-height: 20px; /* Adjust as necessary */
      width: 100%;
      .ql-container {
        height: 100% !important;
        background-color: var(--home-page-bg-color);
      }
      .ql-editor {
        min-height: 20px; /* Adjust as necessary */
        height: 100%;
        background-color: var(--home-page-bg-color);
      }
    }

    .comment-input-button {
      position: absolute;
      padding: 0.5rem 0.8rem;
      right: 1%;
      bottom: -30%;
      z-index: 2;
    }
  }
}

.comment-reply-block {
  width: 100%;
  padding: 0.25rem 0.5rem 0.5rem 3.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .reply-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    bottom: 0;
    left: 0;
    background-color: var(--message-input-bg);
    padding: 0.5rem;
    border-top: 1px solid #707070;
    z-index: 10;
    padding: 0;
    margin: 0 0 0 1rem;
    width: 100%;

    .reply-input-textarea {
      position: relative;
      margin: 0;
      padding: 0;
    }

    .reply-input-button {
      position: absolute;
      padding: 0.5rem 0.8rem;
      right: 1%;
      bottom: 4%;
      z-index: 2;
    }
  }
}

.likes-block {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;

  .likes-avatars {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.5rem;
  }
}

.details-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;

  &-title {
    font-size: $font-size-large;
    @media (max-width: 600px) {
      font-size: $font-size;
    }
    @media (max-width: 450px) {
      font-size: $font-size-small;
    }
    @media (max-width: 350px) {
      font-size: $font-size-smallest;
    }
  }
  &-link {
    text-decoration: none;
    color: var(--text-color);
    font-size: $font-size-large;
    &:hover {
      color: var(--user-management-item-hover);
    }

    @media (max-width: 600px) {
      font-size: $font-size;
    }
    @media (max-width: 450px) {
      font-size: $font-size-small;
    }
    @media (max-width: 350px) {
      font-size: $font-size-smallest;
    }
  }
}

.attends-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  margin: 1rem 0 0 0;

  .attends-block-title {
    font-size: $font-size-large;
  }
  .attends-avatars {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
  }
  .attends-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0.5rem 0.5rem;
    width: 100%;
    &-item {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 1rem;
      width: 100%;
      .item-name {
        margin-left: 1rem;
        text-decoration: none;
        color: var(--text-color);
      }
    }
  }
}

.ql-toolbar {
  background-color: var(--quill-toolbar-bg);
}

.ql-container {
  width: 100%;
  min-height: 10rem;
  height: 100%;
  max-height: 100%;
  .ql-editor {
    color: var(--quill-editor-text-color);
    p {
      pointer-events: none;
    }
  }
  .ql-editor p {
    pointer-events: none;
  }
}
.editor-placeholder.ql-editor {
  color: #aaa;
}

.editor-placeholder.ql-editor h1,
.editor-placeholder.ql-editor p {
  pointer-events: none;
}
