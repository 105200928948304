.notification-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 25rem;
  height: 20rem;
  background-color: #434343;
  box-shadow: 1rem;
  padding: 1rem 2rem;
  outline: "none";
}
