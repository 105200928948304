.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  padding: 0;
  margin: 0;
  z-index: 0;
  background: transparent;
}

.app-body {
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  z-index: 0;
}

.main-content {
  position: fixed;
  top: calc(#{$navbar-height} + 3rem);
  background-color: var(--bg-color);
  overflow-y: auto;
  padding: 0;
  height: calc(100% - #{$navbar-height} - 3rem);
  transition: margin-left 0.4s ease-in-out, width 0.4s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  z-index: 0;

  &.sidebar-open {
    margin-left: $sidebar-width;
    width: calc(100% - #{$sidebar-width});
    z-index: 0;

    &.member-profile-page-active {
      overflow: hidden;
    }
  }

  &.sidebar-closed {
    margin-left: $sidebar-width-small;
    width: calc(100% - #{$sidebar-width-small});
    z-index: 0;
    &.member-profile-page-active {
      overflow: hidden;
    }
  }

  &.no-sidebar {
    margin-left: 0;
    width: 100%;
    top: calc(#{$navbar-height});
    z-index: 0;
  }
}

.notification-snackbar {
  z-index: 999999999;
}
