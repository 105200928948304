.sidebar {
  position: fixed;
  top: calc(#{$navbar-height} + 3rem);
  left: 0;
  width: $sidebar-width;
  height: calc(100% - #{$navbar-height} - 3.5rem);
  overflow: auto;
  overflow-y: auto;
  background-color: var(--sidebar-bg);
  transition: width 0.4s ease-in-out, height 0.4s ease-in-out;
  font-size: $font-size;
  padding: 0;
  margin: 0 0 1rem 0;

  .sidebar-list-body {
    margin-top: $navbar-height;
    width: 100%;
    .tier-item {
      width: 100%;
      .item-text .category-type-list {
        width: 100%;
      }
      .item-text {
        margin: 0;
        padding: 0.5rem 0.1rem;
        font-size: $font-size;

        @media (max-width: 600px) {
          padding: 0.5rem 0;
          font-size: $font-size-small;
        }
      }
      .item-text .truncated-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .group-item {
      width: 100%;

      .item-text.category-type-list {
        width: 100%;
      }
      .item-text {
        margin: 0;
        padding: 0.5rem 0.1rem;
        font-size: $font-size;

        @media (max-width: 600px) {
          padding: 0.5rem 0;
          font-size: $font-size-small;
        }
      }
      .item-text .truncated-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .category-item {
      width: 100%;
      .nav-link {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background-color: var(--sidebar-link-bg);
        color: var(--sidebar-link-text);
        width: 100%;
        font-size: $font-size;
        margin: 0;
        padding: 0;
        padding-left: 2rem;
        flex-grow: 1;

        &:hover {
          background-color: var(--sidebar-active-link-bg);
        }

        .css-qklaqr-MuiListItemIcon-root,
        .css-cveggr-MuiListItemIcon-root {
          width: fit-content;
          min-width: fit-content;
        }

        .item-icon {
          margin: 0;
          padding: 0.25rem 1rem 0 0;

          @media (max-width: 600px) {
            padding: 0.05rem 0.5rem 0 0;
          }
        }

        .item-text {
          margin: 0;
          padding: 0.5rem 0.1rem;
          font-size: $font-size;

          @media (max-width: 600px) {
            padding: 0.5rem 0;
            font-size: $font-size-small;
          }
          &.truncated-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        @media (max-width: 600px) {
          width: 100%;
          padding-left: 0.75rem;
        }
        &.forums {
          @media (max-width: 600px) {
            width: 100%;
          }
        }
        &.sidebar-open {
          @media (max-width: 600px) {
            width: 100%;

            .item-text {
              padding-left: 0.5rem;
            }
          }
        }
      }
      .active {
        background-color: var(--sidebar-active-link-bg);
        color: var(--sidebar-active-link-text);
      }
    }
    @media (max-width: 600px) {
      margin-top: $navbar-height;
    }
  }

  .forums-arrow {
    margin: 0;
    padding: 0.5rem;
    font-size: 2rem;

    @media (max-width: 600px) {
      padding: 0rem;
      font-size: 1.5rem;
    }
  }

  .menu-button .sidebar-menu-button,
  .css-78trlr-MuiButtonBase-root-MuiIconButton-root,
  .css-1drgtl0-MuiButtonBase-root-MuiIconButton-root,
  .css-1yxmbwk,
  .css-1egpgfe {
    position: fixed;
    top: calc(#{$navbar-height} + 3rem);
    left: 0;
    width: $sidebar-width;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: auto;
    padding-left: 1.5rem;
    height: $navbar-height;
    background-color: var(--sidebar-menu-button);
    border-radius: 0;
    margin: 0 0 0 0;
    z-index: 10;

    @media (max-width: 600px) {
      // height: $navbar-height-small;
      // width: $sidebar-width-small;
      padding-left: 0.5rem;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
  .category-type-list {
    padding-left: 1rem;
  }
}

.sidebar.sidebar-closed {
  position: fixed;
  top: calc(#{$navbar-height} + 3rem);
  left: 0;
  width: $sidebar-width-small;
  height: calc(100% - #{$navbar-height} - 3.5rem);
  overflow: auto;
  background-color: var(--sidebar-bg);
  transition: width 0.4s ease-in-out;
  padding: 0;
  margin: 0 0 1rem 0;

  .sidebar-list-body {
    width: 100%;
    .category-item {
      width: 100%;
      .nav-link,
      .active {
        width: 100%;
        padding-left: 0.75rem;
        font-size: $font-size-small;

        &.forums {
          width: 100%;
        }
        .item-icon {
          margin: 0;
          padding: 0.5rem 0;
        }
        .item-text {
          .truncated-text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-left: 0rem;
          }
        }
      }
    }
  }

  .forums-arrow {
    margin: 0;
    padding: 0rem;
    font-size: 1.5rem;
  }
  .menu-button .sidebar-menu-button,
  .css-78trlr-MuiButtonBase-root-MuiIconButton-root,
  .css-1drgtl0-MuiButtonBase-root-MuiIconButton-root,
  .css-1yxmbwk,
  .css-1egpgfe {
    position: fixed;
    top: calc(#{$navbar-height} + 3rem);
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0;
    margin: 0;
    padding-left: 0.75rem;
    width: $sidebar-width-small;
    height: $navbar-height;

    @media (max-width: 600px) {
      top: calc(#{$navbar-height} + 3rem);
      // height: $navbar-height-small;
      padding-left: 0.75rem;
    }
  }
}

.tiers-app-bar {
  position: fixed;
  top: $navbar-height !important;
  left: 0;
  width: 100%;
  height: 3rem;
  z-index: 10 !important;

  .tier-tabs-paper {
    background-color: var(--no-sidebar-bg);
    padding: 0rem 0.25rem;
    margin: 0;
    width: 100%;
    z-index: 10;
  }

  .main-logo-link {
    text-decoration: none;
    cursor: pointer;
    color: var(--text-color);
    font-size: $font-size-large;
    margin: 0 1rem 0 0;

    @media (max-width: 600px) {
      font-size: $font-size;
      margin: 0 0.75rem 0 0;
    }
    @media (max-width: 350px) {
      font-size: $font-size-small;
      margin: 0 0.5rem 0 0;
    }
  }

  .logged-in-block {
    display: flex;
    justify-content: flex-start;
  }
}
