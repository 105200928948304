$navbar-height-small: 3.5rem;
$navbar-height: 4rem;
$member-profile-header-height: 7.5rem;
$message-input-height: 7rem;
$pages-appbars-height: 3rem;
$pages-appbars-height-small: 2.25rem;
$sidebar-width: 15rem;
$sidebar-width-small: 4rem;
$font-size: 1rem;
$font-size-small: 0.75rem;
$font-size-smallest: 0.65rem;
$font-size-medium-sm: 1rem;
$font-size-medium: 1.25rem;
$font-size-large: 1.5rem;
$font-size-largest: 1.75rem;
$post-card-title-font-size: 1.25rem;
$post-card-title-font-size-small: 0.95rem;
$post-card-title-font-size-large: 1.25rem;
$post-card-header-font-size: 0.8rem;
$post-card-header-font-size-small: 0.65rem;
$post-card-header-font-size-large: 1.1rem;
$primary-button-color: #007bff;
$primary-button-text-color: rgb(177, 177, 177);
