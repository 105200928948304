/* Variables (ensure these are defined in your stylesheets or root CSS) */
:root {
  --text-color: var(--text-color); /* Define a variable for text color */
}

.widget-card {
  width: 100%;
  margin: 0;
  padding: 0;
  margin-bottom: 10px;
  .post-card-tier-label {
    margin: 0;
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: var(--post-card-tier-office-label-color);
    background-color: var(--post-card-tier-office-label-bg);
  }
}

/* Apply to all typography in widget headers */
.widget-header {
  background-color: var(--widget-title-bg);
  font-weight: bold;
  width: 100%;
  color: var(--widget-title-text);
  padding: 0px;
  cursor: pointer;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  @media (max-width: 1200px) {
    font-size: $font-size-small;
  }
  .aMuiTypography-root {
    font-size: $font-size-medium;
  }
}

/* Styling for Typography elements within widgets */
.widget-title .MuiTypography-root {
  font-weight: 900;
  text-align: center;
  font-size: var(--font-size-medium); /* Use CSS variable for font size */
}
.widget-content .MuiCardContent {
  background-color: blue;
}
.post-content {
  .widget-mode {
    display: flex; /* Enables flexbox layout */
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Optional: Centers content vertically if needed */
    width: 100%; /* Ensures the div takes the full width */
  }
}

/* Button styling within widgets */
.widget-mode button {
  background-color: #4caf50;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.widget-mode button:hover {
  background-color: #adefad;
  color: #333;
}
.widget-mode-mini {
  font-size: $font-size-small;
}
.events-widget {
  top: 0px;
  align-self: start;
  width: 100%;
}

.events-widget-item {
  display: flex;
  flex-direction: row;
  align-items: center;

  &-day-month {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;

    .day {
      font-size: var(--font-size-large);
      @media (max-width: 1200px) {
        font-size: var(--font-size-small);
      }
    }

    .month {
      font-size: var(--font-size-small);
      @media (max-width: 1200px) {
        font-size: var(--font-size-extra-small);
      }
    }
  }

  &-title {
    cursor: pointer;
    text-align: start;
    font-weight: 600;

    .MuiCardHeader-root {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }

  &-time-date {
    text-align: start;
  }
}

.widget-listings-content {
  font-size: 0.8rem;
  font-weight: 100;
  padding-top: 2px;
  margin: 0px;
}
