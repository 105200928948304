.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  height: $navbar-height;

  &.icon-logo {
    width: "10rem";
    height: "5rem";
  }
  &.app-bar {
    background-color: var(--navbar-bg);
    position: fixed;
    top: 0;
    left: 0;

    /* Base styles */
    img.custom-system {
      width: 100px;
      height: auto;
      object-fit: contain;
      margin-right: 10px;
      background-color: transparent; /* Ensure background of the image is transparent */
    }

    /* Media query for small devices */
    @media only screen and (max-width: 600px) {
      img.custom-system {
        width: 50px;
        margin-right: 0px;
        background-color: transparent; /* Ensure background of the image is transparent */
      }
    }
    /* Media query for small devices */
    @media only screen and (max-width: 300px) {
      img.custom-system {
        width: 0px;
        background-color: transparent; /* Ensure background of the image is transparent */
      }
    }
    .main-logo-link {
      text-decoration: none;
      cursor: pointer;
      color: var(--text-color);
      font-size: $font-size-large;
      margin: 0 1rem 0 0;

      @media (max-width: 600px) {
        font-size: $font-size;
        margin: 0 0.75rem 0 0;
      }
      @media (max-width: 350px) {
        font-size: $font-size-small;
        margin: 0 0.5rem 0 0;
      }
    }

    .logged-in-block {
      display: flex;
      justify-content: flex-start;
    }
  }
  &.btn {
    &.btn-nav-link {
      background-color: var(--nav-btn-link-color);
      padding: 0.5rem 2rem;
      width: fit-content;
      height: fit-content;
      margin: 0 0.5rem 0 0.5rem;
      font-size: medium;

      @media (max-width: 600px) {
        padding: 0.15rem 0.5rem;
        width: fit-content;
        height: fit-content;
        margin: 0 0.25rem;
        font-size: small;
      }
    }
  }
}

.menu-profile {
  width: 100%;
  padding: 0;
  margin: 0;

  .MuiPopover-paper {
    width: 12rem;
    padding: 0;
    margin: 0;
  }
  .menu-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0.5rem 1rem;
    margin: 0 0;
    .tooltip-item {
      display: flex;
      justify-content: flex-start;
      .tooltip-item-div {
        display: flex;
        justify-content: flex-start;
        .menu-text {
          font-size: $font-size-small;
          margin-left: 1rem;
          padding-left: 1rem;
        }
      }
    }
  }
}
