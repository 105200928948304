.data-grid-duty-scheduling {
  .MuiDataGrid-columnHeaders {
    display: none;
  }
}

.tier-perm-data-grid {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .tier-perm-data-grid-header {
    padding: 1rem 2rem;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tier-perm-data-grid-filter {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0.75rem 1rem;

    .tier-perm-data-grid-filter-item {
      width: 100%;
      margin: 0;
      padding: 0.5rem 0.5rem;
    }
  }

  .tier-perm-data-grid-body {
    max-height: 75rem;
    height: 100%;
    width: 100%;
  }
}
