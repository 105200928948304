.custom-calendar {
  height: 80vh;
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-shadow: 0 4px 8px var(--shadow-color);

  .rbc-toolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;

    .rbc-btn-group {
      button {
        background-color: var(--btn-bg-color);
        color: var(--btn-text-color);
        padding: 5px 10px;
        margin-right: 5px;
        border: none;
        border-radius: 4px;
        cursor: pointer;

        &:hover {
          background-color: var(--btn-hover-bg-color);
        }

        &.rbc-active {
          background-color: var(--btn-active-bg-color);
        }
      }
    }

    .rbc-toolbar-label {
      font-size: 1.5rem;
      font-weight: bold;
    }
  }

  .rbc-time-view {
    .rbc-time-header {
      border-bottom: 1px solid var(--border-color);

      .rbc-time-header-cell {
        font-size: 14px;
        font-weight: bold;
        padding: 8px;
        text-align: center;
      }
    }

    .rbc-time-content {
      border-top: 1px solid var(--border-color);

      .rbc-time-gutter {
        font-size: 12px;
        /* background-color: var(--gutter-bg-color); */
        border-right: 1px solid var(--border-color);
      }

      .rbc-event {
        /* background-color: var(--event-bg-color); */
        color: var(--event-text-color);
        border-radius: 4px;
        border: 1px solid var(--event-border-color);
        padding: 4px;
        font-size: 14px;
        font-weight: bold;
        text-wrap: wrap;
        box-shadow: 0 1px 2px var(--shadow-color);

        &.rbc-event-office {
          background-color: var(--office-event-bg-color);
        }

        .rbc-event-content {
          text-align: center;
        }
      }

      .rbc-time-slot {
        background-color: var(--time-slot-bg-color);
        border-top: 1px solid var(--border-color);
      }
    }
  }

  .rbc-month-view {
    .rbc-header {
      border-bottom: 1px solid var(--border-color);
      font-weight: bold;
      text-align: center;
    }

    .rbc-today {
      background-color: var(--today-bg-color);
    }
    .rbc-day-bg {
      &.rbc-off-range-bg {
        background-color: var(--off-range-bg-color) !important;
        color: var(--off-range-text-color);
      }
    }
    .rbc-date-cell {
      padding: 8px;
      text-align: center;

      &.rbc-off-range {
        background-color: var(--off-range-bg-color);
        color: var(--off-range-text-color);
      }
    }

    .rbc-event {
      /* background-color: var(--event-bg-color); */
      color: var(--event-text-color);
      border-radius: 4px;
      padding: 4px;
      font-size: 12px;
      font-weight: bold;
      margin: 1px 0;
    }
  }

  .rbc-week-view {
    .rbc-time-header-content {
      font-size: 16px;
      font-weight: bold;
    }

    .rbc-time-gutter {
      font-size: 12px;
    }

    .rbc-event-content {
      font-size: 14px;
      padding: 4px;
    }

    .rbc-time-slot {
      font-size: 12px;
    }

    .rbc-current-time-indicator {
      /* background-color: var(--current-time-color); */
      height: 2px;
    }
  }

  .rbc-day-header {
    text-align: center;
    /* background-color: var(--day-header-bg-color); */
    font-size: 14px;
    font-weight: bold;
    padding: 8px;
  }

  .rbc-today {
    /* background-color: var(--today-bg-color); */
  }

  .rbc-off-range-bg {
    /* background-color: var(--off-range-bg-color); */
  }

  .rbc-day-bg {
    &.rbc-off-range-bg {
      /* background-color: var(--off-range-bg-color); */
    }

    &.rbc-sun,
    &.rbc-sat {
      /* background-color: var(--weekend-bg-color); */
    }
  }

  .rbc-overlay {
    .rbc-overlay-header {
      /* background-color: var(--overlay-header-bg-color); */
      color: var(--overlay-header-text-color);
      font-weight: bold;
      padding: 5px;
      border-radius: 4px 4px 0 0;
    }

    .rbc-overlay-content {
      /* background-color: var(--overlay-content-bg-color); */
      padding: 10px;
      border: 1px solid var(--border-color);
      border-radius: 0 0 4px 4px;
    }
  }
}

.rbc-event-label {
  display: none;
}
.rbc-allday-cell {
  display: none;
}
