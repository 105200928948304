.caregory-chat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  overflow: hidden;
  height: calc(100% - #{$navbar-height} - 3.5rem);
  width: 100%;
  position: relative;

  .message-container {
    width: 100%;
    height: calc(100%);
    padding: 0 0.5rem;
    margin: 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
    padding-bottom: 0.5rem;
    -webkit-overflow-scrolling: touch;

    .loading-spinner {
      display: flex;
      justify-content: center;
      padding: 1rem;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      transition: opacity 0.8s ease-in-out;
      opacity: 0.7;
    }
    .message-block {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .date-header {
        text-align: center;
        margin-top: 10px;
        margin-bottom: 10px;
        color: var(--message-block-date-header);
        font-size: 0.9em;
      }

      .divider-new-message {
        color: var(--divider-new-messages);
      }
      &-body {
        width: 100%;
        max-width: 50rem;
        min-width: 20rem;
        padding: 0.25rem 0.4rem;
        border-radius: 10px;
        display: flex;
        flex-direction: column;

        &.message-block-body-sender {
          background-color: var(--message-block-item-sender);
        }
        &.message-block-body-receiver {
          background-color: var(--message-block-item-receiver);
          color: var(--message-block-text-color-receiver);
        }

        @media (max-width: 600px) {
          max-width: 15rem;
          min-width: 7rem;
        }
        .message-block-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0;
          margin: 0 0 0.75rem 0;

          &-title {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 0.5rem;

            &-avatar {
              padding: 0;
            }

            &-user-name {
              background-color: var(--message-block-item-initials);
              width: fit-content;
              padding: 0;
              border-radius: 50%;
            }

            &-time {
              font-size: 0.65rem;
              @media (max-width: 600px) {
                font-size: 0.5rem;
              }
            }
          }
        }
      }

      &-left {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
      }

      &-right {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
      }
    }
  }

  .message-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: var(--message-input-bg);
    padding: 0.5rem;
    border-top: 1px solid #ccc;
    z-index: 10;
    padding: 0;
    margin: 0;
    transition: width 0.4s ease-in-out;

    .message-input-textarea {
      position: relative;
      margin: 0;
      padding: 0;
    }

    .message-input-button {
      position: absolute;
      padding: 0.5rem 0.8rem;
      right: 1%;
      bottom: 4%;
      z-index: 2;
    }

    &.sidebar-open {
      margin-left: $sidebar-width;
      width: calc(100% - #{$sidebar-width});
    }

    &.sidebar-closed {
      margin-left: $sidebar-width-small;
      width: calc(100% - #{$sidebar-width-small});
    }
  }
}
