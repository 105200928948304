.theme-light {
  --bg-color: #eceff1;
  --hover-bg-color: #777777;
  --header-bg: #ddd;
  --bg-color-opt2: rgb(242, 242, 242);
  --no-sidebar-bg: #d4d4d4;
  --group-paper-bg: #d9d9d9;
  --text-color: #2c2c2c;
  --text-color-listing-table-col-titles: #ff0000;
  --text-color-listing-table-col-titles-upcoming: #fff;
  --bg-tag-color: grey;
  --sidebar-item-badge-bg: hsl(218, 100%, 50%);
  --sidebar-tier-tab-item-badge-bg: hsl(284, 100%, 50%);
  --sidebar-item-badge-color: #ddd;
  --widget-title-bg: hsl(218deg 53% 43%);
  --widget-title-text: hsl(0, 0%, 98%);
  --home-page-text-color: #fff;
  --home-page-bg-color: #eceff1;
  --home-page-main-title-color: #fff;
  --home-page-main-title-border: #ddd;
  --home-page-main-subtitle-shadow-color: #0000001a;
  --listing-custom-card-bg: #fdfdfd;
  --navbar-bg: #f8f8f8;
  --second-navbar-bg: #f0f0f0;
  --second-navbar-profile-bg: #f0f0f0;
  --search-bar-text: #222;
  --search-bar-bg: hsl(0, 0%, 91%);
  --search-bar-hover-bg: hsl(0, 0%, 88%);
  --search-bar-hover-text: #222;
  --sidebar-bg: #ffffff;
  --sidebar-link-bg: #ffffff;
  --sidebar-active-link-bg: #e8e8e8;
  --sidebar-link-text: #333333;
  --sidebar-active-link-text: #333333;
  --sidebar-menu-button: #e8e8e8;
  --table-row-even: #f4f4f4;
  --table-row-odd: #f9f9f9;
  --table-header-bg: hsl(0, 0%, 75%);
  --user-management-item: #bababa;
  --user-management-item-hover: #818181;
  --notifications-item: #d4d4d4;
  --notifications-item-read: #c7c7c7;
  --notifications-item-unread: #acacac;
  --chat-item-unread: #989898;
  --chat-item-read: #c7c7c7;
  --message-block-item: #c7c7c7;
  --message-block-date-header: #474747;
  --message-block-item-sender: hsl(0, 0%, 90%);
  --message-block-item-receiver: hsl(223, 98%, 60%);
  --message-block-text-color-receiver: #fff;
  --message-block-text-color-sender: #333;
  --message-input-bg: #eceff1;
  --nav-btn-link-color: #1976d2;
  --nav-btn-link-text-color: rgb(158, 158, 158);
  --nav-btn-create-color: #57b524;
  --nav-btn-create-text: #fff;
  --divider-new-messages: #ee0000;
  --quill-toolbar-bg: #dadada;
  --quill-editor-text-color: #000;
  --post-card-tier-office-label-color: hsl(218, 100%, 30%);
  --post-card-tier-office-label-bg: #ddd;
  --post-card-new-post-label-color: hsl(218, 100%, 31%);
  --post-card-new-post-label-bg: #bc9898;
  --listing-table-col-titles-bg: green;
  --dialog-title-color: #1976d2;
  //Floor Duty Scheduling
  --btn-bg-color: #0056b3;
  --btn-text-color: #fff;
  --btn-hover-bg-color: #004080;
  --btn-active-bg-color: #002d5a;
  --time-slot-bg-color: #d5d5d590;
  --color-office-duty: #4caf50;
  --color-other-duty: #2196f3;
  --color-past-event: #d3d3d3;
  --color-user-duty: #0a4b78;
  --color-available-duty: #008000;
  --color-phone-duty: #ff9900;
  --color-office-duty: #4f94d4;
  --calendar-bg-color: #f7f7f7;
  --calendar-text-color: #000;
  --calendar-day-text-color: #000;
  --off-range-bg-color: #e0e0e0;
  --off-range-text-color: #000;
}

.theme-dark {
  --bg-color: #5a5a5a;
  --bg-color-opt2: rgb(102, 102, 102);
  --header-bg: #555;
  --hover-bg-color: #353535;
  --no-sidebar-bg: #7b7b7b;
  --group-paper-bg: #484848;
  --text-color: #fff;
  --text-color-dark: #444;
  --text-color-listing-table-col-titles: #ff0000;
  --text-color-listing-table-col-titles-upcoming: #fff;
  --sidebar-item-badge-bg: hsl(218, 100%, 50%);
  --sidebar-tier-tab-item-badge-bg: hsl(284, 100%, 50%);
  --sidebar-item-badge-color: #fff;
  --widget-title-bg: hsl(218deg 53% 43%);
  --widget-title-text: hsl(0, 0%, 98%);
  --home-page-text-color: #dfdfdf;
  --home-page-bg-color: #767676;
  --home-page-main-title-color: #c5c5c5;
  --home-page-main-title-border: rgb(189, 189, 189);
  --home-page-main-subtitle-shadow-color: #e4e4e41a;
  --listing-custom-card-bg: #838383;
  --navbar-bg: #565656;
  --second-navbar-bg: #5a5a5a;
  --second-navbar-profile-bg: #a7a3a3;
  --bg-tag-color: lightgrey;
  --search-bar-bg: #5a5a5a;
  --search-bar-hover-bg: #949494;
  --sidebar-bg: #1e1e1e;
  --sidebar-link-bg: #1e1e1e;
  --sidebar-active-link-bg: #797878;
  --sidebar-link-text: #fff;
  --sidebar-active-link-text: #fff;
  --sidebar-menu-button: #5a5a5a;
  --table-row-odd: #a1a1a1;
  --table-row-even: #8f8f8f;
  --table-header-bg: hsl(0, 0%, 35%);
  --user-management-item: #919191;
  --user-management-item-hover: #6b6b6b;
  --notifications-item: #8c8c8c;
  --notifications-item-read: #616161;
  --notifications-item-unread: #929292;
  --chat-item-unread: #b1b1b1;
  --chat-item-read: #4d4d4d;
  --message-block-item: #4d4d4d;
  --message-block-date-header: #e4e4e4;
  --message-block-item-sender: #4d4d4d;
  --message-block-item-receiver: #396ffa;
  --message-block-text-color-receiver: #fff;
  --message-block-text-color-sender: #ddd;
  --message-input-bg: #626262;
  --nav-btn-link-color: #1976d2;
  --nav-btn-link-text-color: rgb(183, 183, 183);
  --nav-btn-create-color: #4ea121;
  --divider-new-messages: #ee0000;
  --quill-toolbar-bg: #989898;
  --quill-editor-text-color: #fff;
  --post-card-tier-office-label-color: hsl(218, 100%, 49%);
  --post-card-tier-office-label-bg: #ddd;
  --post-card-new-post-label-color: hsl(218, 100%, 31%);
  --post-card-new-post-label-bg: #bc9898;
  --dialog-title-color: #1976d2;
  //Floor Duty Scheduling
  --color-office-duty: #8bc34a;
  --color-other-duty: #03a9f4;
  --weekday-bg-color: #2e2e2e29;
  --weekend-bg-color: #3a3a3a4d;
  --btn-bg-color: #0056b3;
  --btn-text-color: #fff;
  --btn-hover-bg-color: #004080;
  --btn-active-bg-color: #002d5a;
  --gutter-bg-color: #3a3a3a;
  --event-bg-color: #0056b3;
  --event-text-color: #fff;
  --event-border-color: #555;
  --time-slot-bg-color: #2e2e2e23;
  --color-office-duty: #4caf50;
  --color-other-duty: #2196f3;
  --color-past-event: #d3d3d3;
  --color-user-duty: #03a9f4; /* Light Blue */

  --calendar-bg-color: #f7f7f7;
  --calendar-text-color: #000;
  --calendar-day-text-color: #fff;
}
