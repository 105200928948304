.edit-profile-data-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: $font-size;
  overflow: hidden;
  margin: 0;
  padding: 0;

  .edit-profile-data-tabs-header {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: var(--background-color);

    .MuiTab-root {
      font-size: $font-size;
      @media (max-width: 600px) {
        font-size: $font-size-small;
      }

      @media (max-width: 350px) {
        font-size: $font-size-smallest;
      }
    }
  }
  .edit-profile-data-tabs-body {
    flex-grow: 1;
    overflow-y: auto;
  }
}

.member-profile-edit-form-from-nb {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  .member-profile-edit-form-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: $font-size;
    overflow: hidden;
    margin: 0;
    padding: 0;

    .member-profile-edit-form-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0.5rem 0.15rem 1rem 1rem;
      position: fixed;
      top: calc(#{$navbar-height} + 4rem);
      left: 0;
      background-color: var(--second-navbar-bg);
      margin: 0;
      z-index: 9;
      overflow: hidden;

      .header-profile-image-block {
        .header-profile-avatar {
          display: flex;
          flex-direction: column;
          &-span {
            text-align: center;
            font-style: italic;
            color: var(--text-color);
          }
        }
      }
      .member-profile-image {
        width: 7.5rem;
        height: 7.5rem;
        margin-right: 1rem;
        @media (max-width: 600px) {
          width: 4rem;
          height: 4rem;
          margin-right: 0.5rem;
        }
        @media (max-width: 350px) {
          width: 4rem;
          height: 4rem;
          margin-right: 0.5rem;
        }
      }
      .header-profile-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .header-profile-title-name {
          font-size: calc(#{$font-size} + 0.5rem);
          @media (max-width: 600px) {
            font-size: calc(#{$font-size-small} + 0.5rem);
          }
          @media (max-width: 350px) {
            font-size: calc(#{$font-size-smallest} + 0.15rem);
          }
        }
        .header-profile-title-profile-title {
          font-size: calc(#{$font-size});
          @media (max-width: 600px) {
            font-size: calc(#{$font-size-small});
          }
          @media (max-width: 350px) {
            font-size: calc(#{$font-size-smallest});
          }
        }
      }
    }

    .member-profile-edit-form-fields {
      width: 100%;
      overflow-y: auto;
      margin: 8rem 0 0 0;
      padding: 0;
      font-size: $font-size;
      flex-grow: 1;
      padding-bottom: 5rem;

      @media (max-width: 600px) {
        font-size: $font-size-small;
        margin: 5rem 0 0 0;
      }
      @media (max-width: 350px) {
        font-size: $font-size-smallest;
      }
    }
    .member-profile-edit-form-actions {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      overflow: hidden;
      margin: 1rem 0 0 0;
      padding: 1rem;
      font-size: $font-size;
      overflow: hidden;
      background-color: var(--second-navbar-bg);
      display: flex;
      justify-content: flex-end;

      .actions-button {
        margin: 0.35rem 1rem;
        padding: 0.5rem 1rem;
        font-size: $font-size;

        @media (max-width: 600px) {
          font-size: $font-size-small;
          margin: 0.35rem 1rem;
          padding: 0.5rem 1rem;
        }
        @media (max-width: 350px) {
          font-size: $font-size-smallest;
          margin: 0.25rem 0.5rem;
          padding: 0.3rem 0.5rem;
        }
      }
      @media (max-width: 600px) {
        font-size: $font-size-small;
        padding: 0.7rem;
      }
      @media (max-width: 350px) {
        font-size: $font-size-smallest;
        padding: 0.5rem;
      }
    }

    @media (max-width: 600px) {
      font-size: $font-size-small;
    }

    @media (max-width: 350px) {
      font-size: $font-size-small;
    }
  }
}

.user-account-profile-edit-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-size: $font-size;
  overflow: hidden;
  margin: 0;
  padding: 0;

  .profile-edit-form-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0.5rem 0.15rem 1rem 1rem;
    position: fixed;
    top: calc(#{$navbar-height} + 4rem);
    left: 0;
    background-color: var(--second-navbar-bg);
    margin: 0;
    z-index: 9;
    overflow: hidden;

    .profile-edit-form-image-block {
      .header-profile-avatar {
        display: flex;
        flex-direction: column;
        &-span {
          text-align: center;
          font-style: italic;
          color: var(--text-color);
        }
      }
    }
    .member-profile-image {
      width: 7.5rem;
      height: 7.5rem;
      margin-right: 1rem;
      @media (max-width: 600px) {
        width: 4rem;
        height: 4rem;
        margin-right: 0.5rem;
      }
      @media (max-width: 350px) {
        width: 4rem;
        height: 4rem;
        margin-right: 0.5rem;
      }
    }
    .header-profile-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      .header-profile-title-name {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        font-size: calc(#{$font-size} + 0.5rem);
        &-item {
          margin: 0.5rem;
        }
        @media (max-width: 600px) {
          flex-direction: column;
          justify-content: center;
          font-size: calc(#{$font-size-small} + 0.5rem);
        }
        @media (max-width: 350px) {
          flex-direction: column;
          justify-content: center;
          font-size: calc(#{$font-size-smallest} + 0.15rem);
        }
      }
      .header-profile-title-profile-title {
        font-size: calc(#{$font-size});
        @media (max-width: 600px) {
          font-size: calc(#{$font-size-small});
        }
        @media (max-width: 350px) {
          font-size: calc(#{$font-size-smallest});
        }
      }
    }
  }

  .profile-edit-form-fields {
    width: 100%;
    overflow-y: auto;
    margin: 13rem 0 0 0;
    padding: 0;
    font-size: $font-size;
    flex-grow: 1;
    padding-bottom: 5rem;

    @media (max-width: 600px) {
      font-size: $font-size-small;
    }
    @media (max-width: 350px) {
      font-size: $font-size-smallest;
    }
  }
  .profile-edit-form-actions {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    overflow: hidden;
    margin: 1rem 0 0 0;
    padding: 1rem;
    font-size: $font-size;
    overflow: hidden;
    background-color: var(--second-navbar-bg);
    display: flex;
    justify-content: flex-end;

    .actions-button {
      margin: 0.35rem 1rem;
      padding: 0.5rem 1rem;
      font-size: $font-size;

      @media (max-width: 600px) {
        font-size: $font-size-small;
        margin: 0.35rem 1rem;
        padding: 0.5rem 1rem;
      }
      @media (max-width: 350px) {
        font-size: $font-size-smallest;
        margin: 0.25rem 0.5rem;
        padding: 0.3rem 0.5rem;
      }
    }
    @media (max-width: 600px) {
      font-size: $font-size-small;
      padding: 0.7rem;
    }
    @media (max-width: 350px) {
      font-size: $font-size-smallest;
      padding: 0.5rem;
    }
  }

  @media (max-width: 600px) {
    font-size: $font-size-small;
  }

  @media (max-width: 350px) {
    font-size: $font-size-small;
  }
}

.drawer-header {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.25rem;
  z-index: 1100;

  .close-button {
    margin-left: auto;
    color: var(--nav-btn-link-text-color);
  }
}
