.management-page {
  height: calc(100% - #{$navbar-height});
  margin-top: calc(#{$navbar-height});
  overflow-y: auto;
  width: 100%;

  .user-profile-management {
    margin: 0 1rem 1rem 1rem;
    padding: 0 1rem 1rem 1rem;
    .tier-perm-row {
      display: flex;
      gap: 0.5rem;
    }
  }
  &-body {
    height: calc(100% - #{$navbar-height} - 3rem);
    width: 100%;
    overflow-y: auto;
  }
}

.detail-row .detail-label {
  font-weight: bold;
  background-color: #f0f0f0;
}

.detail-row {
  border-bottom: 1px solid #e0e0e0;
}

.groupDetailsCard {
  &__header {
    font-size: 1.2rem;
    font-weight: bold;
  }

  &__noGroupMessage {
    font-weight: bold;
    font-size: 1.5rem;
    color: var(--error-color);
    text-align: center;
    padding: 20px;
  }

  &__actions {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
    padding: 10px 0;
  }

  &__memberDetails {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 0;
  }
}
