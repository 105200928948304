.table-row {
  &:nth-child(odd) {
    background-color: var(--table-row-odd);
  }
  &:nth-child(even) {
    background-color: var(--table-row-even);
  }
}
/* Dan's Additions */
/* Post list container */
.post-list {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Adjust the gap between posts as needed */
}

/* Individual post item */
.post-item {
  width: 100%;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  background-color: #fff; /* Set background color to white */
}

/* Post title */
.post-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}

/* Post author */
.post-author {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
}
/* Post Summary */
.post-summary {
  font-size: 14px;
  margin-bottom: 5px;
  margin-left: 10px;
}
/* Post created date */
.post-created-at {
  font-size: 13px;
  font-weight: 900;
  color: #c90909;
  margin-bottom: 5px;
}
/* Tag Box */
.tag-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 5px;
  padding: 5px;
  border: 1px solid #ddd;
  background-color: var(--home-page-bg-color);
  border-radius: 5px;
}
.tag-box.MuiBox-root {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
/* Listing Table tag box */
.css-15qcuyh {
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
/* Post actions container */
.post-actions {
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-left: "auto";
  justify-content: flex-end; /* Align menu to the right */
}
.post-item-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 3px;
  padding: 5px;
  cursor: pointer;
  background-color: var(--bg-color2); /* Set background color to white */
}
/* Menu button */
.kbt-menu-button {
  background: none;
  border: none;
  cursor: pointer;
}

/* Menu container */
.menu {
  display: none;
  position: absolute;
  top: 0; /* Position at the top */
  right: 0; /* Position at the right */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 0;
  z-index: 1000;
}

.menu.open {
  display: block;
}

/* Menu item */
.menu-item {
  padding: 5px 10px;
  cursor: pointer;
}
.coming-soon-banner {
  position: absolute;
  top: 0px; /* Adjust as needed */
  left: 9px; /* Adjust as needed */
  width: 398px; /* Make it as wide as the container (image) */
  background-color: rgba(255, 165, 0, 0.75); /* Orange with some transparency */
  color: white;
  padding: 5px 10px;
  border-radius: 0px;
  font-weight: bold;
  text-align: center; /* Center the text */
  box-sizing: border-box; /* Include padding in the width calculation */
}
/* Menu item hover effect */
.menu-item:hover {
  background-color: #f0f0f0;
}

.listing-table {
  &-label {
    text-align: right !important;
    font-weight: 900 !important;
  }
  &-address {
    font-size: large;
    font-weight: 800;
  }
  &-list-price {
    font-size: medium;
    color: green;
    font-weight: 500;
  }

  &-title {
    font-size: medium;
    font-weight: 700;
  }
  &-highlights {
    font-size: small;
    font-weight: 300;
  }
  &-moreInfo {
    cursor: "pointer";
    color: white;
    font-weight: bolder;
    padding: 10px 20px;
    background-color: green;
    border: 0.5px solid #ddd;
  }
}

.listings-table {
  margin-top: calc(#{$navbar-height} + 6rem);
  height: calc(100% - #{$navbar-height} - 8rem);
  overflow: hidden;

  .listings-table-header {
    position: fixed;
    top: calc(#{$navbar-height} + 7rem);
    padding: 0;
    margin: 0;
    background-color: var(--sidebar-bg);
    z-index: 9;
    width: 100%;
    max-width: 100%;
    display: table-header-group;
    transition: left 0.4s ease-in-out, width 0.4s ease-in-out;

    .table-cols-titles {
      .upcoming,
      .mine {
        width: 50%;
        text-align: center;
        font-size: 0.95rem;
        border: 1px solid var(--header-bg);
        cursor: pointer;
        background-color: transparent;
        color: var(--text-color);
        font-weight: 900;
        &.active {
          background-color: var(--listing-table-col-titles-bg);
          color: var(--text-color-listing-table-col-titles-upcoming);
          font-weight: 900;
        }
      }
      @media (max-width: 825px) {
        .upcoming,
        .mine {
          font-size: 0.85rem;
          padding: 0.5rem 1rem;
          margin: 0;
        }
      }
      @media (max-width: 725px) {
        .upcoming,
        .mine {
          font-size: 0.75rem;
          padding: 0.25rem 1rem;
          margin: 0;
        }
      }
      @media (max-width: 600px) {
        .upcoming,
        .mine {
          font-size: 0.6rem;
          padding: 0;
          margin: 0;
        }
      }
    }

    .table-second-row {
      .table-second-row-message {
        margin: 0.1rem 0.1rem 0.5rem 0.1rem;
        font-size: 0.95rem;
        color: var(--text-color-listing-table-col-titles);
        text-align: center;
        @media (max-width: 825px) {
          font-size: 0.85rem;
        }
        @media (max-width: 725px) {
          font-size: 0.75rem;
        }
        @media (max-width: 600px) {
          font-size: 0.6rem;
        }
      }
    }
    &.sidebar-open {
      left: $sidebar-width;
      width: calc(100% - #{$sidebar-width});
    }
    &.sidebar-closed {
      left: $sidebar-width-small;
      width: calc(100% - #{$sidebar-width-small});
    }
  }
}

.users-table-paper {
  padding: 0;
  max-width: 100%;
  max-height: 900px;
  font-size: 12;
  line-height: 1.2;
  overflow-y: auto;
  overflow-x: hidden;
}
