.user-management-item {
  background-color: var(--user-management-item);
  margin: 0.5rem 0.1rem;
  cursor: pointer;
  &:hover {
    background-color: var(--user-management-item-hover);
  }
}

.btn {
  &.btn-create {
    background-color: var(--nav-btn-create-color);
    padding: 0.5rem 2rem;
    color: var(--nav-btn-create-text);
    width: fit-content;
    height: fit-content;
    margin: 0 0.25rem 0 0;
    cursor: pointer;
    pointer-events: auto;
    z-index: 10;
    position: relative;

    @media (max-width: 600px) {
      padding: 0.15rem 1rem;
      width: fit-content;
      height: fit-content;
      margin: 0 0.5rem 0 0.5rem;
    }
  }
  &.btn-attend {
    background-color: var(--nav-btn-create-color);
    color: var(--nav-btn-create-text);
    padding: 0.5rem 0.5rem;
    width: fit-content;
    height: fit-content;
    margin: 0 0.25rem 0 0;
    border-radius: 5px;
    font-size: $font-size;

    @media (max-width: 600px) {
      padding: 0.05rem 0.15rem;
      width: fit-content;
      height: fit-content;
      margin: 0 0.25rem;
      font-size: $font-size-small;
    }
    @media (max-width: 450px) {
      font-size: $font-size-smallest;
    }
  }
  &.btn-input-hidden {
    padding: 0.5rem 2rem;
    width: 100%;
    max-width: 5rem;
    height: 100%;
    max-height: 1rem;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    pointer-events: auto;
    z-index: 10;
  }
}

.reacircleadmin-select-brokerage {
  .reacircleadmin-select-brokerage-select {
    height: fit-content;
    padding: 1rem;
    margin: 1rem 0 2rem 0;

    .MuiSelect-select {
      padding: 0;
      font-size: 0.875rem;
      @media (max-width: 600px) {
        font-size: 0.75rem;
      }
      @media (max-width: 350px) {
        font-size: 0.75rem;
      }
    }
    @media (max-width: 600px) {
      padding: 0.75rem;
      margin: 0.75rem 0 1.5rem 0;
    }
    @media (max-width: 350px) {
      padding: 0.5rem;
      margin: 0.5rem 0 0.5rem 0;
    }
  }
}

.file-input {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
