.tabs-paper {
  background-color: var(--no-sidebar-bg);
  padding: 0rem 0.25rem;
  margin: 0;
  width: 100%;
  &.userlist {
    overflow: auto;
  }
}

.tier-tabs-paper {
  background-color: var(--no-sidebar-bg);
  padding: 0rem 0.25rem;
  margin: 0;
  width: 100%;
}

.team-paper {
  background-color: var(--no-sidebar-bg);
  padding: 0.5rem 0.5rem;
  margin: 0 0.25rem 0 0;

  @media (max-width: 600px) {
    padding: 0.15rem 0.5rem;
    margin: 0 0.5rem 0 0.5rem;
  }
}
