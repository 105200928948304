/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -60px;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.fileContainer {
  margin: 20px;
  font-family: Arial, sans-serif;
}

.fileContainer ul {
  list-style: none;
  padding: 0;
}

.fileContainer li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.fileContainer li .tooltip {
  margin-right: 10px;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.fileContainer a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
}

.tooltip a {
  margin-left: 5px;
}

.fileContainer button {
  margin-left: auto;
}

.directory > .tooltip {
  cursor: pointer;
}

.directory > .tooltip .MuiSvgIcon-root {
  transition: transform 0.3s ease;
}

.directory.expanded > .tooltip .MuiSvgIcon-root {
  transform: rotate(90deg); /* Rotate icon for visual effect */
}

/* Add some MUI-specific styles to align with Material UI's design */
.fileContainer li {
  padding: 16px;
  border-bottom: 1px solid #ddd;
}

.fileContainer li:last-child {
  border-bottom: none;
}

.fileContainer .MuiSvgIcon-root {
  font-size: 20px;
  margin-right: 10px;
}

.fileContainer .MuiButton-root {
  margin-left: 10px;
  padding: 8px 16px;
}


.drag-drop-container {
  border: 1px solid black;
  border-radius: 15px;
  background-color: var(--bg-color);
  padding: 20px;
  margin-bottom: 0px;
  transition: border 0.3s ease-in-out;
}

.drag-drop-container.active {
  border: 2px dashed green;
}

.drag-drop-text {
  margin: 10px 0;
}

.file-preview-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.file-preview-image {
  width: 100px;
  height: auto;
  margin-right: 10px;
}

.input-directory {
  margin-top: 10px;
}

.upload-button {
  margin-top: 20px;
  background-color: #4CAF50; /* Green */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.upload-button:disabled {
  background-color: #ccc;
  color: #666;
}

.progress-bar {
  width: 100%;
  height: 20px;
  background-color: #ddd;
}

.progress-bar-value {
  height: 100%;
  background-color: #4CAF50;
  text-align: center;
  line-height: 20px;
  color: black;
}
.control-section {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.input-directory {
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  box-sizing: border-box; /* Ensures padding does not add to the width */
}