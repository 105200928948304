.member-card {
  padding: 0.15rem 0.5rem;
  margin: 0 0.25rem;
  cursor: pointer;

  &.grid {
    .member-card-item {
      .card-media-image {
        width: 100%;
        height: 15rem;
        object-fit: contain;
        margin-bottom: 0;
        padding-bottom: 0;
      }
    }
  }
  &.row {
    width: 100%;

    .member-card-item {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .card-media-image {
        width: 100px;
        height: 100px;
        object-fit: cover;
        margin-left: 0rem;
        margin-bottom: 0;
        padding-bottom: 0;
        flex: 0 1 25%;
      }

      .card-title {
        font-weight: bolder;
        flex: 0 1 25%;
        width: 100%;
      }
    }
  }

  @media (max-width: 600px) {
    padding: 0.15rem 0.5rem;
    width: 12rem;
    height: fit-content;
    margin: 0 0.25rem;

    .card-media-image {
      height: 8rem;
    }
  }
}

.members-page.conference {
  background-image: url("/assets/imgs/nsc24/RLP-NSC-background.jpg");
  background-size: cover; /* This will make the image cover the entire element */
  background-position: center; /* This will center the image */
  background-repeat: no-repeat; /* This prevents the image from repeating */
}
.members-page {
  width: 100%;
  height: calc(100% - #{$navbar-height});
  margin-top: calc(#{$navbar-height});
  overflow-y: auto;

  .members-page-body {
    &.grid {
      display: flex;
      gap: 16px;
    }

    &.row {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-wrap: nowrap;
      width: 100%;

      .members-page-body-item {
        width: 100%;
        max-width: 35rem;
      }
    }
  }

  .app-bar {
    color: var(--text-color);
    background-color: var(--second-navbar-bg);
    position: fixed;
    top: calc(#{$navbar-height} + 3rem);
    padding: 0;
    margin: 0;
    font-size: $font-size-large;
    transition: left 0.4s ease-in-out, width 0.4s ease-in-out;
    height: $navbar-height;
    z-index: 9;

    .main-title {
      padding: 0.75rem;
      margin: 0rem 0rem;
      font-size: $font-size-large;
      @media (max-width: 600px) {
        padding: 0.5rem;
        margin: 0rem;
        font-size: calc(0.01rem + #{$font-size});
      }

      @media (max-width: 350px) {
        padding: 0.25rem;
        margin: 0rem;
        font-size: calc(0.01rem + #{$font-size-small});
      }
    }

    .sorting-select {
      height: fit-content;
      padding: 0;
      margin: 0 1rem 0 0;

      .MuiSelect-select {
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        font-size: 0.875rem;
        @media (max-width: 600px) {
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          font-size: 0.75rem;
        }
        @media (max-width: 350px) {
          padding-top: 0.15rem;
          padding-bottom: 0.15rem;
          font-size: 0.75rem;
        }
      }

      .sorting-select-item {
        height: fit-content;
        padding: 0;
        margin: 0;
        @media (max-width: 600px) {
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          font-size: 0.75rem;
        }
        @media (max-width: 350px) {
          padding-top: 0.15rem;
          padding-bottom: 0.15rem;
          font-size: 0.75rem;
        }
      }
    }
    @media (max-width: 768px) {
      width: calc(100% - #{$sidebar-width} + 3.5rem);
      left: calc(#{$sidebar-width} - 3.5rem);
    }

    @media (max-width: 600px) {
      left: $sidebar-width-small;
      width: calc(100% - #{$sidebar-width-small});
    }
    &.sidebar-open {
      left: $sidebar-width;
      width: calc(100% - #{$sidebar-width});
    }
    &.sidebar-closed {
      left: $sidebar-width-small;
      width: calc(100% - #{$sidebar-width-small});
    }
  }
}

.member-drawer {
  width: 30rem;
  padding: 0;
  margin: 0;
  transition: width 0.4s ease-in-out;
  overflow: hidden;

  .MuiDrawer-paper {
    transition: width 0.4s ease-in-out;
  }

  @media (max-width: 768px) {
    width: 25rem;
    height: fit-content;
  }
  @media (max-width: 600px) {
    width: 20rem;
    height: fit-content;
  }
  @media (max-width: 350px) {
    width: 18.5rem;
    height: fit-content;
  }
  .member-profile-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: $font-size;
    overflow: hidden;
    margin: 0;
    padding: 0;
    transition: width 0.4s ease-in-out;

    .member-profile-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0.5rem 0.15rem 1rem 0.15rem;
      position: sticky;
      width: 100%;
      margin: 0;

      .header-profile-image-block {
        .header-profile-avatar {
          display: flex;
          flex-direction: column;
          &-span {
            text-align: center;
            font-style: italic;
            font-size: smaller;
            color: var(--text-color);
          }
          &-offline-span {
            font-size: 0.75rem;
            text-align: center;
            font-style: italic;
            color: var(--text-color);
          }
        }
      }

      .member-profile-image {
        width: 7rem;
        height: 7rem;
      }
    }
    .member-profile-tabs {
      width: 100%;
      overflow: hidden;
      height: calc(100% - 48px);
      margin: 0;
      padding: 0;

      .member-profile-tabs-header {
        width: 100%;
        position: fixed;
        top: calc(1.5rem + #{$member-profile-header-height});
        background-color: var(--second-navbar-bg);
        z-index: 9;
        font-size: $font-size;

        .MuiTab-root {
          font-size: $font-size;
          @media (max-width: 600px) {
            font-size: $font-size-small;
          }

          @media (max-width: 350px) {
            font-size: $font-size-smallest;
          }
        }
        @media (max-width: 600px) {
          top: calc(0.5rem + #{$member-profile-header-height});
          font-size: $font-size-small;
        }
        @media (max-width: 350px) {
          font-size: $font-size-smallest;
        }
      }
      .member-profile-tabs-body {
        height: calc(100vh - #{$member-profile-header-height} - 2rem);
        margin: 2rem 0 0 0;
        padding: 0.5rem 0;
        width: 100%;
        align-items: left;
        overflow-y: auto;
        max-height: 100%;

        @media (max-width: 600px) {
          padding: 2rem 0 0 0;
          margin: 0.5rem 0 0 0;
          font-size: $font-size-small;
        }
      }
    }

    @media (max-width: 600px) {
      font-size: $font-size-small;
    }

    @media (max-width: 350px) {
      font-size: $font-size-small;
    }
  }
}

.member-profile-page-container {
  .app-bar {
    color: var(--text-color);
    background-color: var(--second-navbar-bg);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: $navbar-height;
    padding: 0;
    margin: 0;
    font-size: $font-size-large;
    transition: left 0.4s ease-in-out, width 0.4s ease-in-out;
    height: $navbar-height;
    z-index: 9;

    .main-title {
      padding: 0.75rem;
      margin: 0rem 0rem;
      font-size: $font-size-large;

      @media (max-width: 768px) {
        padding: 0.5rem;
        margin: 0.5rem 0rem;
        font-size: $font-size;
      }

      @media (max-width: 600px) {
        padding: 0.5rem;
        margin: 0.5rem 0rem;
        font-size: calc(0.01rem + #{$font-size});
      }

      @media (max-width: 350px) {
        padding: 0.1rem;
        margin: 0.5rem 0rem;
        font-size: calc(0.01rem + #{$font-size-small});
      }
    }

    .app-bar-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0rem;
      margin: 0rem;

      .sorting-select {
        height: fit-content;
        padding: 0;
        margin: 0 1rem 0 0;

        .MuiSelect-select {
          padding: 0.5rem 1.75rem;
          font-size: $font-size;

          @media (max-width: 768px) {
            padding: 0.5rem 1.25rem;
            font-size: 0.75rem;
          }

          @media (max-width: 600px) {
            padding: 0.5rem 1rem;
            font-size: 0.75rem;
          }

          @media (max-width: 350px) {
            padding: 0.25rem 0 0.25rem 0;
            font-size: 0.5rem;
          }
        }
        .MuiSvgIcon-root {
          padding: 0;
          margin: 0;
          right: 0.5rem;

          @media (max-width: 600px) {
            right: 0.15rem;
          }
        }

        .sorting-select-item {
          height: fit-content;
          padding: 0;
          margin: 0;
        }
      }
      .btn {
        &.btn-create {
          background-color: var(--nav-btn-create-color);
          color: var(--nav-btn-create-text);
          font-weight: 700;
          padding: 0.5rem 2rem;
          width: fit-content;
          height: fit-content;
          margin: 0 0.25rem 0 0;

          @media (max-width: 768px) {
            font-size: 0.65rem;
          }

          @media (max-width: 600px) {
            padding: 0.75rem 0.75rem;
            width: fit-content;
            height: fit-content;
            margin: 0 0.15rem;
            font-size: 0.65rem;
          }
          @media (max-width: 350px) {
            padding: 0.5rem 0.5rem;
            width: fit-content;
            height: fit-content;
            margin: 0 0.05rem 0 0.05rem;
            font-size: 0.5rem;
          }
        }
      }
      .btn-text {
        font-size: $font-size;
        margin-left: 0.75rem;
      }
    }
    @media (max-width: 768px) {
      font-size: $font-size;
    }

    @media (max-width: 600px) {
      left: $sidebar-width-small;
      width: calc(100% - #{$sidebar-width-small});
      font-size: $font-size-small;
      height: $navbar-height;
      top: $navbar-height;
    }
    &.sidebar-open {
      left: $sidebar-width;
      width: calc(100% - #{$sidebar-width});
    }
    &.sidebar-closed {
      left: $sidebar-width-small;
      width: calc(100% - #{$sidebar-width-small});
    }
  }
}

.member-profile-page {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;
  height: calc(100% - #{$navbar-height});
  margin-top: calc(#{$navbar-height} + 1rem);

  .member-profile-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: $font-size;
    overflow: hidden;
    margin: 0;
    padding: 0;

    .member-profile-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0.5rem 0.15rem 1rem 0.5rem;
      position: fixed;
      top: calc(#{$navbar-height} + 4rem);
      left: $sidebar-width;
      width: 100%;
      background-color: var(--second-navbar-profile-bg);
      margin: 0;
      z-index: 9;
      &.sidebar-opened {
        left: $sidebar-width-small;
      }
      @media (max-width: 600px) {
        top: calc(#{$navbar-height} + 4rem);
      }

      .header-profile-image-block {
        .header-profile-avatar {
          display: flex;
          flex-direction: column;
          &-span {
            text-align: center;
            font-style: italic;
            color: var(--text-color);
          }
        }
      }
      .member-profile-image {
        width: 5rem;
        height: 5rem;
        margin-right: 1rem;
        @media (max-width: 600px) {
          width: 4rem;
          height: 4rem;
          margin-right: 0.5rem;
        }
        @media (max-width: 350px) {
          width: 4rem;
          height: 4rem;
          margin-right: 0.5rem;
        }
      }

      .header-profile-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .header-profile-title-name {
          font-size: calc(#{$font-size} + 0.5rem);
          @media (max-width: 600px) {
            font-size: calc(#{$font-size-small} + 0.5rem);
          }
          @media (max-width: 350px) {
            font-size: calc(#{$font-size-smallest} + 0.15rem);
          }
        }
        .header-profile-title-profile-title {
          font-size: calc(#{$font-size});
          @media (max-width: 600px) {
            font-size: calc(#{$font-size-small});
          }
          @media (max-width: 350px) {
            font-size: calc(#{$font-size-smallest});
          }
        }
      }
    }
    .member-profile-tabs {
      width: 100%;
      overflow: hidden;
      margin: 0;
      padding: 0;

      .member-profile-tabs-header {
        width: 100%;
        position: fixed;
        top: calc(#{$member-profile-header-height} + 9.5rem);
        left: $sidebar-width;
        background-color: var(--second-navbar-bg);
        z-index: 9;
        font-size: $font-size;
        margin: 0;
        padding: 0rem 0.15rem 0.1rem 0.5rem;

        &.sidebar-opened {
          left: $sidebar-width-small;
        }

        .MuiTab-root {
          font-size: $font-size;
          @media (max-width: 600px) {
            font-size: $font-size-small;
          }

          @media (max-width: 350px) {
            font-size: $font-size-smallest;
          }
        }
        @media (max-width: 600px) {
          top: calc(#{$member-profile-header-height} + 9rem);
          font-size: $font-size-small;
        }
        @media (max-width: 350px) {
          top: calc(#{$member-profile-header-height} + 8rem);
          font-size: $font-size-smallest;
        }
      }
      .member-profile-tabs-body {
        height: calc(100vh - #{$member-profile-header-height} - 7rem);
        margin: 7rem 0 0 0;
        padding: 0.5rem 0;
        width: 100%;
        overflow-y: auto;
        max-height: 100%;
        align-items: left;
        width: 100%;

        @media (max-width: 768px) {
          height: calc(100vh - #{$member-profile-header-height} - 6rem);
          margin: 8rem 0 0 0;
          padding: 0.5rem 0;
          width: 100%;
          overflow-y: auto;
          max-height: 100%;
          width: 100%;
        }

        @media (max-width: 600px) {
          height: calc(100vh - #{$member-profile-header-height} - 7rem);
          padding: 1rem 0 0 0;
          margin: 9rem 0 0 0;
          font-size: $font-size-small;
          width: 100%;
        }
        @media (max-width: 350px) {
          height: calc(100vh - #{$member-profile-header-height} - 5rem);
          padding: 1rem 0 0 0;
          margin: 8rem 0 0 0;
          font-size: $font-size-small;
          width: 100%;
        }
        .member-profile-tabs-tabpanel {
          width: 100%;

          .member-profile-tabs-tab {
            width: 100%;
          }
        }
      }
    }

    @media (max-width: 600px) {
      font-size: $font-size-small;
    }

    @media (max-width: 350px) {
      font-size: $font-size-small;
    }
  }
}

.member-posts-tab {
  width: 100%;
  margin: 0;
  padding: 0;
  .member-posts-tab-body {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.member-comments-tab {
  width: 100%;
  margin: 0;
  padding: 0;
  .member-comments-tab-body {
    margin: 0;
    padding: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .post-comment-card {
      width: 100%;
    }
  }
}

.member-data-tab-body {
  background-color: var(--bg-color);
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.data-info-header {
  color: var(--text-color);
  margin-bottom: 10px;
  font-weight: bold;
}

.data-info-content {
  margin-left: 20px;
}

.data-link {
  margin-left: 5px;
  color: var(--nav-btn-link-text-color);
  text-decoration: underline;
}

.data-info-item:not(:last-child) {
  margin-bottom: 5px;
}

.member-data-tab {
  width: 100%;
  margin: 0;
  padding: 0;
  padding-bottom: 20px;
  .member-data-tab-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    margin: 0;
    padding: 0;

    .data-info-item {
      margin: 0;
      padding: 0.75rem 0 0.75rem 0;
      font-size: $font-size;

      @media (max-width: 600px) {
        font-size: $font-size-small;
        margin: 0;
        padding: 0.5rem 0 0.5rem 0;
      }

      @media (max-width: 350px) {
        font-size: $font-size-small;
        margin: 0;
        padding: 0.25rem 0 0.25rem 0;
      }
    }
  }
}

.member-message {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .message-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0.5rem 0;

    .message-input-textarea {
      position: relative;
      margin: 0;
      padding: 0;
    }

    .message-send-button {
      position: absolute;
      padding: 0.5rem 0.8rem;
      right: 4%;
      bottom: 12%;
      z-index: 2;
    }

    .message-cancel-button {
      position: absolute;
      padding: 0.5rem 0.8rem;
      right: 15%;
      bottom: 10%;
      z-index: 2;
    }
  }
}

.member-profile-page-main {
  width: 100%;
  overflow: hidden;
}

.member-profile-edit-drawer {
  .member-profile-edit-drawer-header {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.25rem 1rem;
    z-index: 1100;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .close-button {
      margin-left: auto;
      color: var(--nav-btn-link-text-color);
    }
  }
}

.member-profile-edit-form-from-mp {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  .member-profile-edit-form-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: $font-size;
    overflow: hidden;
    margin: 0;
    padding: 0;

    .member-profile-edit-form-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0.5rem 0.15rem 1rem 1rem;
      position: fixed;
      top: calc(#{$navbar-height} + 4rem);
      left: 0;
      background-color: var(--second-navbar-bg);
      margin: 0;
      z-index: 9;
      overflow: hidden;

      .header-profile-image-block {
        display: flex;
        flex-direction: column;

        .header-profile-avatar {
          display: flex;
          flex-direction: column;
          &-span {
            text-align: center;
            font-style: italic;
            color: var(--text-color);
          }
        }
      }
      .member-profile-image {
        width: 7.5rem;
        height: 7.5rem;
        margin-right: 1rem;
        @media (max-width: 600px) {
          width: 4rem;
          height: 4rem;
          margin-right: 0.5rem;
        }
        @media (max-width: 350px) {
          width: 4rem;
          height: 4rem;
          margin-right: 0.5rem;
        }
      }
      .header-profile-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .header-profile-title-name {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: center;
          font-size: calc(#{$font-size} + 0.5rem);
          &-item {
            margin: 0.5rem;
          }
          @media (max-width: 600px) {
            flex-direction: column;
            justify-content: center;
            font-size: calc(#{$font-size-small} + 0.5rem);
          }
          @media (max-width: 350px) {
            flex-direction: column;
            justify-content: center;
            font-size: calc(#{$font-size-smallest} + 0.15rem);
          }
        }
        .header-profile-title-profile-title {
          font-size: calc(#{$font-size});
          @media (max-width: 600px) {
            font-size: calc(#{$font-size-small});
          }
          @media (max-width: 350px) {
            font-size: calc(#{$font-size-smallest});
          }
        }
      }
    }

    .member-profile-edit-form-fields {
      width: 100%;
      overflow-y: auto;
      margin: 15rem 0 0 0;
      padding: 0;
      font-size: $font-size;
      flex-grow: 1;
      padding-bottom: 6rem;

      @media (max-width: 600px) {
        font-size: $font-size-small;
        margin: 17rem 0 0 0;
      }
      @media (max-width: 350px) {
        font-size: $font-size-smallest;
      }
    }
    .member-profile-edit-form-actions {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      overflow: hidden;
      margin: 1rem 0 0 0;
      padding: 1rem;
      font-size: $font-size;
      overflow: hidden;
      background-color: var(--second-navbar-bg);
      display: flex;
      justify-content: flex-end;

      .actions-button {
        margin: 0.35rem 1rem;
        padding: 0.5rem 1rem;
        font-size: $font-size;

        @media (max-width: 600px) {
          font-size: $font-size-small;
          margin: 0.35rem 1rem;
          padding: 0.5rem 1rem;
        }
        @media (max-width: 350px) {
          font-size: $font-size-smallest;
          margin: 0.25rem 0.5rem;
          padding: 0.3rem 0.5rem;
        }
      }
      @media (max-width: 600px) {
        font-size: $font-size-small;
        padding: 0.7rem;
      }
      @media (max-width: 350px) {
        font-size: $font-size-smallest;
        padding: 0.5rem;
      }
    }

    @media (max-width: 600px) {
      font-size: $font-size-small;
    }

    @media (max-width: 350px) {
      font-size: $font-size-small;
    }
  }
}

.member-profile-manager {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow: hidden;

  .member-profile-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: $font-size;
    overflow: hidden;
    margin: 0;
    padding: 0;

    .member-profile-header {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0.5rem 0.15rem 1rem 0.5rem;
      width: 100%;
      background-color: var(--second-navbar-bg);
      margin: 0;
      z-index: 9;

      .header-profile-image-block {
        .header-profile-avatar {
          display: flex;
          flex-direction: column;
          &-span {
            text-align: center;
            font-style: italic;
            color: var(--text-color);
          }
        }
      }
      .member-profile-image {
        width: 5rem;
        height: 5rem;
        margin-right: 1rem;
        @media (max-width: 600px) {
          width: 4rem;
          height: 4rem;
          margin-right: 0.5rem;
        }
        @media (max-width: 350px) {
          width: 4rem;
          height: 4rem;
          margin-right: 0.5rem;
        }
      }

      .header-profile-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .header-profile-title-name {
          font-size: calc(#{$font-size} + 0.5rem);
          @media (max-width: 600px) {
            font-size: calc(#{$font-size-small} + 0.5rem);
          }
          @media (max-width: 350px) {
            font-size: calc(#{$font-size-smallest} + 0.15rem);
          }
        }
        .header-profile-title-profile-title {
          font-size: calc(#{$font-size});
          @media (max-width: 600px) {
            font-size: calc(#{$font-size-small});
          }
          @media (max-width: 350px) {
            font-size: calc(#{$font-size-smallest});
          }
        }
      }
    }

    @media (max-width: 600px) {
      font-size: $font-size-small;
    }

    @media (max-width: 350px) {
      font-size: $font-size-small;
    }
  }
}
.data-section {
  margin-bottom: 16px;
}
.data-section-content {
  margin-left: 20px; // Adjust as needed for your design
}
.data-header {
  margin-bottom: 8px;
  color: var(--text-color); /* Dark blue color */
  font-weight: 800;
}
.red-icon {
  color: red;
}

.data-item {
  margin-bottom: 4px;
  margin-left: 10px;

  color: var(--text-color); /* Slightly lighter blue color */
}

.data-link {
  margin-left: 8px;
  color: var(--nav-btn-link-text-color); /* Bright blue color */
  text-decoration: none; /* Underline on hover only */
}

.data-link:hover {
  text-decoration: underline;
}
