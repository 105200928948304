.notifications-body {
  display: flex;
  flex-direction: column;
  padding: 0;
  overflow: hidden;

  .notifications-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 3rem;
    background-color: var(--navbar-bg);
    position: sticky;
    top: 0;
    z-index: 10;
    padding: 0.5rem;
    margin: 0;

    .notifications-header-actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .notifications-header-text {
        font-size: $font-size;
        @media (max-width: 600px) {
          font-size: $font-size-small;
        }
      }
    }
  }

  .notifications-list {
    margin: 0.5rem 0;
    padding: 0.5rem 0.5rem;
    overflow: auto;
    overflow-x: hidden;
    width: 30rem;
    min-height: 25rem;
    max-height: 45rem;
    font-size: $font-size;

    @media (max-width: 600px) {
      width: 22.5rem;
      min-height: 15rem;
      max-height: 35rem;
      font-size: $font-size-small;
    }

    @media (max-width: 350px) {
      width: 17.5rem;
      min-height: 15rem;
      max-height: 25rem;
      font-size: $font-size-small;
    }

    .notifications-item-read {
      margin: 0.5rem 0;
      padding: 0.5rem 0.5rem;
      border-radius: 10px;
      border: 0rem solid var(--navbar-bg);
      cursor: pointer;
      background-color: var(--notifications-item-read);
      max-width: 100%;
      .notifications-message {
        width: 50%;
      }
      .notifications-text-read {
        font-weight: 400;
        font-size: $font-size;
        @media (max-width: 600px) {
          font-size: $font-size-small;
        }
      }
    }

    .notifications-item-unread {
      position: relative;
      margin: 0.5rem 0;
      padding: 0.5rem 0.5rem;
      border-radius: 10px;
      border: 0.15rem solid #7e7e7e;
      cursor: pointer;
      background-color: var(--notifications-item-unread);
      .notifications-message {
        width: 50%;
      }
      .notifications-text-unread {
        font-weight: 700;
        font-size: $font-size;
        @media (max-width: 600px) {
          font-size: $font-size-small;
        }
      }
      .notification-unread-badge {
        position: absolute;
        top: 16%;
        right: 15.5%;
        color: #e13737;
        font-weight: 250;
        font-size: $font-size-small;
      }
    }
  }
}

.notification-link {
  text-decoration: none;
  color: #7b7bc5;
  font-weight: bold;

  &:hover {
    color: #52527d;
    font-weight: bolder;
  }
}

.be-notifications-snackbar {
  z-index: 999999999999999;
}
