.create-edit-dialog {
  z-index: 9999999999999999999999999999999999;
  display: flex;
  flex-direction: column;
  width: 100%;

  .dialog-title {
    font-size: 2rem;
    text-align: center;
    @media (max-width: 768px) {
      font-size: 1.75rem;
    }
    @media (max-width: 600px) {
      font-size: 1.5rem;
    }
    @media (max-width: 350px) {
      font-size: 1rem;
    }
  }

  .sub-items-list {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-title {
      font-size: $font-size;
      width: 100%;
      @media (max-width: 600px) {
        font-size: $font-size-small;
      }
    }
    .sub-list-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 0.75rem 0.5rem;
      padding: 0;
      width: 100%;
      @media (max-width: 600px) {
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        border-bottom: 1px solid var(--bg-color);
      }
      &-subitem {
        margin: 0.5rem 0.25rem;
      }
    }
  }
}
