.brokerage-page {
  padding: 1.75rem;
  margin: 0;
  width: 100%;
  margin-top: $navbar-height;
  overflow-y: auto;

  .main-title {
    text-align: center;
    padding: 0.75rem;
    margin: 0rem 0rem;
    font-size: $font-size-large;
    @media (max-width: 600px) {
      padding: 0.5rem;
      margin: 0rem;
      font-size: $font-size-large;
    }

    @media (max-width: 350px) {
      padding: 0.25rem;
      margin: 0rem;
      font-size: $font-size;
    }
  }

  .brokerage-page-body {
    .main-title {
      text-align: center;
      padding: 0.75rem;
      margin: 0rem 0rem;
      font-size: $font-size-large;
      @media (max-width: 600px) {
        padding: 0.5rem;
        margin: 0rem;
        font-size: $font-size-large;
      }

      @media (max-width: 350px) {
        padding: 0.25rem;
        margin: 0rem;
        font-size: $font-size;
      }
    }
    .second-title {
      text-align: center;
      padding: 0.75rem;
      margin: 0rem 0rem;
      font-size: $font-size;
      @media (max-width: 600px) {
        padding: 0.5rem;
        margin: 0rem;
        font-size: $font-size-small;
      }

      @media (max-width: 350px) {
        padding: 0.25rem;
        margin: 0rem;
        font-size: $font-size-small;
      }
    }
    .actions {
      .actions-block {
        .actions-block-list {
          width: 100%;
          margin: 0;
          padding: 0.3rem 0.5rem;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .actions-block-list-item {
            background-color: var(--user-management-item);
            margin: 0.5rem 0.1rem;
            font-size: $font-size;

            cursor: pointer;
            &:hover {
              background-color: var(--user-management-item-hover);
            }
            @media (max-width: 600px) {
              padding: 0.5rem;
              margin: 0rem;
              font-size: $font-size-small;
            }

            @media (max-width: 350px) {
              padding: 0.25rem;
              margin: 0rem;
              font-size: $font-size-small;
            }
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    padding: 1.25rem;
  }
  @media (max-width: 600px) {
    margin-top: $navbar-height-small;
    padding: 1rem;
    height: calc(100% - #{$navbar-height-small});
  }
  @media (max-width: 450px) {
    padding: 0.75rem;
  }
  @media (max-width: 350px) {
    padding: 0.5rem;
  }
}
.brokerage-card {
  width: 100%;
  margin: 0 0 1rem 0;
  font-size: $font-size;
  padding: 16px;
  background-color: var(--bg-color-opt2);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  .brokerage-card-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-left: 0.5rem;
    margin: 0rem 0rem;
    font-size: $font-size-large;

    .brokerage-card-header-image {
      width: 7rem;

      @media (max-width: 600px) {
        width: 5rem;
      }

      @media (max-width: 350px) {
        width: 4rem;
      }
    }

    .brokerage-card-header-title {
      padding-left: 0.75rem;
      margin: 0rem 0rem;
      font-size: $font-size-large;
      font-weight: 700;
      @media (max-width: 600px) {
        padding: 0.5rem;
        margin: 0rem;
        font-size: $font-size-large;
      }
      @media (max-width: 350px) {
        padding: 0.25rem;
        margin: 0rem;
        font-size: $font-size;
      }
    }

    .brokerage-card-header-subtitle {
      padding-left: 0.75rem;
      margin: 0rem 0rem;
      font-size: $font-size-medium;
      font-weight: 200;
      @media (max-width: 600px) {
        padding: 0.5rem;
        margin: 0rem;
        font-size: $font-size-large;
      }
      @media (max-width: 350px) {
        padding: 0.25rem;
        margin: 0rem;
        font-size: $font-size;
      }
    }
  }

  .brokerage-card-content {
    padding-left: 1.5rem;
    margin: 0rem 0rem;
    font-size: $font-size;
    @media (max-width: 600px) {
      padding-left: 1rem;
      margin: 0rem;
      font-size: $font-size-small;
    }

    @media (max-width: 350px) {
      padding-left: 0.25rem;
      margin: 0rem;
      font-size: $font-size-smallest;
    }

    .brokerage-card-content-field {
      padding-left: 0.75rem;
      margin: 0rem 0rem;
      font-size: $font-size;
      @media (max-width: 600px) {
        padding: 0.5rem;
        margin: 0rem;
        font-size: $font-size-small;
      }

      @media (max-width: 350px) {
        padding: 0.25rem;
        margin: 0rem;
        font-size: $font-size-smallest;
      }
    }
  }
  @media (max-width: 600px) {
    font-size: $font-size-small;
  }

  @media (max-width: 350px) {
    font-size: $font-size-small;
  }

  .headerStrong {
    font-weight: bold;
    margin-right: 8px;
  }

  .brokerageDetails {
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 8px;
  }

  .nestedBrokerages {
    margin-top: 16px;
  }

  .brokerage-table {
    width: 100%;
    border-collapse: collapse; // Ensures that border spacing is removed
    border: 2px solid #ccc; // Adds a solid border around the entire table with a light grey color
    border-radius: 8px; // Adds rounded corners to the table
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Adds a subtle shadow around the table
    border: 0.5px solid #004aad; // Change to a blue border, for example
    .brokerage-row {
      border-bottom: none; // Continue to remove the individual row lines if not needed

      .brokerage-cell-header {
        font-weight: bold;
        background-color: #f4f4f4; // Light grey background for header cells
        color: #333; // Dark text for better readability
      }

      .brokerage-cell {
        background-color: #fff; // White background for data cells
      }
    }

    .nested-brokerages {
      .header-strong {
        padding-top: 10px; // Extra space above the nested brokerages section
        font-weight: 900;
      }
    }
  }
}

/* user-management.css */
.user-management-box {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.user-management-list {
  width: 100%;
  padding: 0;
  margin: 0;
}

.user-management-item {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  gap: 10px; /* Space between icon and text */
  cursor: pointer;
}

.icon {
  color: #1976d2; /* Theme color for icons */
}
.brokerage-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem; /* add some gap between items */
  font-size: 0.7rem;
}

.user-table-compact {
  /* Make the table more compact */
  border-collapse: collapse;
  width: 100%;
  font-size: 11px;
  line-height: 1.1;

  /* Reduce padding and spacing */
  & th,
  & td {
    padding: 2px 2px;
    text-align: left;
  }

  /* Make the table header sticky */
  & th {
    position: sticky;
    top: 0;
    background-color: var(--bg-color);
    color: var(--text-color);
  }

  /* Add some visual separation */
  & tr:not(:last-child) {
    border-bottom: 1px solid #ddd;
  }

  /* Make the table more responsive */
  @media (max-width: 768px) {
    & th,
    & td {
      padding: 2px 4px;
    }
  }
}

.user-table thead th {
  color: var(--text-color);
  font-weight: 700;
  background-color: var(--bg-color);
  font-size: $font-size-smallest;
}

.tabs-paper-brokerage-ecosys {
  width: 100%;
}
.brokerage-tabs-paper {
  background-color: var(--bg-color-opt2);
  width: 100%;
}

.brokerage-edit-drawer-header {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.25rem 1rem;
  z-index: 1100;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .close-button {
    margin-left: auto;
    color: var(--nav-btn-link-text-color);
  }
}

.brokerage-edit-form {
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;

  .brokerage-edit-form-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    font-size: $font-size;
    overflow: hidden;
    margin: 0;
    padding: 0;

    .brokerage-edit-form-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0.5rem 0.15rem 1rem 1rem;
      position: fixed;
      top: calc(#{$navbar-height} + 4rem);
      left: 0;
      background-color: var(--second-navbar-bg);
      margin: 0;
      z-index: 9;
      overflow: hidden;

      .header-image-block {
        display: flex;
        flex-direction: column;

        .brokerage-avatar {
          display: flex;
          flex-direction: column;
          &-span {
            text-align: center;
            font-style: italic;
            color: var(--text-color);
          }
        }
      }
      .brokerage-image {
        width: 7.5rem;
        // height: 7.5rem;
        margin-right: 1rem;

        @media (max-width: 600px) {
          width: 4rem;
          height: 4rem;
          margin-right: 0.5rem;
        }
        @media (max-width: 350px) {
          width: 4rem;
          height: 4rem;
          margin-right: 0.5rem;
        }
      }
      .header-profile-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .header-profile-title-name {
          font-size: calc(#{$font-size} + 0.5rem);
          @media (max-width: 600px) {
            font-size: calc(#{$font-size-small} + 0.5rem);
          }
          @media (max-width: 350px) {
            font-size: calc(#{$font-size-smallest} + 0.15rem);
          }
        }
        .header-profile-title-profile-title {
          font-size: calc(#{$font-size});
          @media (max-width: 600px) {
            font-size: calc(#{$font-size-small});
          }
          @media (max-width: 350px) {
            font-size: calc(#{$font-size-smallest});
          }
        }
      }
    }

    .brokerage-edit-form-fields {
      width: 100%;
      overflow-y: auto;
      margin: 15rem 0 0 0;
      padding: 0;
      font-size: $font-size;
      flex-grow: 1;
      padding-bottom: 6rem;

      @media (max-width: 600px) {
        font-size: $font-size-small;
        margin: 12rem 0 0 0;
      }
      @media (max-width: 350px) {
        font-size: $font-size-smallest;
      }
    }
    .brokerage-edit-form-actions {
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 10;
      width: 100%;
      overflow: hidden;
      margin: 1rem 0 0 0;
      padding: 1rem;
      font-size: $font-size;
      overflow: hidden;
      background-color: var(--second-navbar-bg);
      display: flex;
      justify-content: flex-end;

      .actions-button {
        margin: 0.35rem 1rem;
        padding: 0.5rem 1rem;
        font-size: $font-size;

        @media (max-width: 600px) {
          font-size: $font-size-small;
          margin: 0.35rem 1rem;
          padding: 0.5rem 1rem;
        }
        @media (max-width: 350px) {
          font-size: $font-size-smallest;
          margin: 0.25rem 0.5rem;
          padding: 0.3rem 0.5rem;
        }
      }
      @media (max-width: 600px) {
        font-size: $font-size-small;
        padding: 0.7rem;
      }
      @media (max-width: 350px) {
        font-size: $font-size-smallest;
        padding: 0.5rem;
      }
    }

    @media (max-width: 600px) {
      font-size: $font-size-small;
    }

    @media (max-width: 350px) {
      font-size: $font-size-small;
    }
  }
}
