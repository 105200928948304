
.conference {
background-image: url('/assets/imgs/nsc24/RLP-NSC-background.jpg');
background-size: cover; /* This will make the image cover the entire element */
background-position: center -150px; /* This will center the image */
background-repeat: no-repeat; /* This prevents the image from repeating */
}

.login-page {
  padding: 1.75rem;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  // margin-top: calc(#{$navbar-height});
  height: 100% ;


  @media (max-width: 768px) {
    padding: 1.25rem;
  }
  @media (max-width: 600px) {
    padding: 1rem;
  }
  @media (max-width: 450px) {
    padding: 0.75rem;
  }
  @media (max-width: 350px) {
    padding: 0.5rem;
  }

  .login-page-body {
    width: 100%;
    max-width: 55rem;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    
  }

}