.home-page {
  width: 100%;
  background-color: var(
    --home-page-bg-color
  ); /* Light grey background for subtlety */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.75rem;
  margin: 0.5rem 0;
  font-size: $font-size;
  font-family: "Arial", sans-serif;
  font-weight: 300;
  color: var(
    --home-page-text-color
  ); /* Dark grey color for better readability */
  margin-top: calc(#{$navbar-height});
  height: calc(100% - #{$navbar-height});
  overflow: auto;

  .home-title {
    width: 100%;
    font-size: $font-size-large;
    box-shadow: 0px 4px 8px var(--home-page-main-subtitle-shadow-color); /* Subtle shadow */
    border: 1px solid var(--home-page-main-title-border); /* Light border */
    font-family: "Arial", sans-serif;
    background-color: black; /* Set the background to black */
    color: white; /* Set the text color to white */
    font-weight: bold; /* Make the text bold */
    display: flex; /* Use flexbox for layout */
    align-items: center; /* Center the text vertically */
    justify-content: center; /* Center the text horizontally, if needed */
    border-radius: 20px;
    color: var(
      --home-page-text-color
    ); /* Dark grey color for better readability */

    .main-title {
      text-align: center;
      padding: 0 2rem;
      margin: 1rem 0rem;
      font-size: calc(0.5rem + #{$font-size-large});
      font-family: "Arial", sans-serif;
      color: var(--home-page-main-title-color);
      font-style: italic;
      margin-top: 10px;

      @media (max-width: 600px) {
        padding: 1rem;
        margin: 1rem 0rem;
        font-size: calc(0.5rem + #{$font-size});
      }

      @media (max-width: 350px) {
        padding: 0.5rem;
        margin: 0.5rem 0rem;
        font-size: calc(0.5rem + #{$font-size-small});
      }
    }

    .second-title {
      text-align: center;
      padding: 0 2rem;
      margin: 0.5rem 0rem;
      font-size: calc(0.5rem + #{$font-size});
      @media (max-width: 600px) {
        padding: 1rem;
        margin: 0.75rem 0rem;
        font-size: calc(0.5rem + #{$font-size-small});
      }

      @media (max-width: 350px) {
        padding: 0.5rem;
        margin: 0.5rem 0rem;
        font-size: calc(0.05rem + #{$font-size-small});
      }
    }
  }

  .reacircleadmin-select {
    margin: 1rem 0 0 0;
    padding: 0 0 0 0;
    width: 100%;
    max-width: 25rem;

    @media (max-width: 600px) {
      margin: 1rem 0 0 0;
      padding: 0 0 0 0;
      max-width: 20rem;
    }
  }

  // Top Banner Links
  .home-page-top-banner {
    background-color: var(--background-color);
    padding: 0px; /* Reduced padding */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 0.25px solid black;
    height: 100px;
    display: flex;
    align-items: flex-start;
    width: 100%;
    max-width: 55rem;
  }

  .links-title {
    font-size: 1.25rem; /* Adjust the font size as needed */
    font-weight: bolder; /* Make the title bold */
    color: var(--home-page-text-color);
    background-color: black;
    transition: height 1.5s ease-in-out; /* Smooth transition for height */
    cursor: pointer; /* Make the title clickable */
    height: 100px;
    line-height: 33px;
    text-align: center; /* Center the title text */
    display: flex; /* Ensure it's a block element to take full width */
    justify-content: center;
    align-items: center;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, 1fr);
    padding-left: 5px;
    gap: 5px; /* Provide a little gap for visual separation */
    width: 100%;
    height: 100%;
    max-height: 0; /* Start with a max-height of 0 */
    overflow: hidden; /* Hide the overflow */
    transition: max-height 0.5s ease-in-out; /* Animate the max-height property */
  }

  .grid-item a {
    display: block;
    color: var(--text-color);
    text-decoration: none;
    font-size: 0.75rem;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 4px; /* Padding for touch targets and visual appearance */
  }

  .grid-item a:hover {
    background-color: var(--hover-bg-color); /* New background color on hover */
    color: var(--hover-text-color); /* Text color on hover */
    text-decoration: none;
  }

  /* CSS file */
  .home-page-top-banner.no-border {
    border: none; /* Removes the border */
    box-shadow: none;
  }
  .links-title.min-size {
    /* Styles for the smaller "Quick Links" text */
    margin-top: 0px;
    font-size: 0.8rem; /* Smaller font size */
    height: 100px;
    width: 50px;
    writing-mode: vertical-rl;
    transform: rotate(180deg);
    text-align: center;
    white-space: nowrap;
    transition: width 1.5s ease-in-out; /* Smooth transition for height */
  }
  /* Media Query for screens smaller than 800px */
  @media (max-width: 1050px) {
    .home-page-top-banner {
      display: none; /* Hide the banner on small screens */
    }
  }

  .grid-container.show {
    max-height: 500px; /* Set a max-height that will accommodate all the links when expanded */
  }

  // End Top Banner LInks

  .home-page-body {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    gap: 0;
    width: 100%;
    max-width: 100%;

    .posts-list {
      max-width: 100%;
      width: 100%;
      margin: 1rem 1rem 0 0;
      flex: 0 1 75%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 0 0 0 0;

      &.grid-view {
        max-width: 85%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
        grid-gap: 1rem;
        justify-content: center;
        align-items: stretch;
      }

      &.list-view {
        display: flex;
        flex-direction: column;
      }
      @media (max-width: 1300px) {
        max-width: 45rem;
        margin: 1rem 0.5rem 0 0;
      }
      @media (max-width: 1200px) {
        max-width: 43rem;
        margin: 1rem 0.5rem 0 0;
      }
      @media (max-width: 1190px) {
        max-width: 100%;
      }
    }

    .widget-list {
      width: 100%;
      max-width: 28rem;
      margin: 1rem 0 0 0;
      flex: 0 1 20%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      padding: 0 0 0 0;

      @media (max-width: 1300px) {
        max-width: 25rem;
      }
      @media (max-width: 1200px) {
        max-width: 24rem;
      }
    }
  }

  &.app-bar {
    color: var(--text-color);
    background-color: var(--second-navbar-bg);
    position: fixed;
    top: calc(#{$navbar-height} + 3rem);
    padding: 0 1rem;
    margin: 0 0.04rem;
    height: $navbar-height;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: $font-size-large;
    transition: left 0.4s ease-in-out, width 0.4s ease-in-out;
    z-index: 9;
    .appbar-title {
      font-size: inherit;
    }

    .app-bar-actions {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      padding: 0;
      margin: 0;
    }

    .main-title {
      padding: 0.75rem;
      margin: 0rem 0rem;
      font-size: $font-size-large;
      @media (max-width: 600px) {
        padding: 0.5rem;
        margin: 0rem;
        font-size: $font-size-large;
      }

      @media (max-width: 350px) {
        padding: 0.25rem;
        margin: 0rem;
        font-size: $font-size;
      }
    }

    .btn {
      &.btn-create {
        background-color: var(--nav-btn-create-color);
        color: var(--nav-btn-create-text);
        font-weight: 700;
        padding: 0.5rem 2rem;
        width: fit-content;
        height: fit-content;
        margin: 0 0.25rem 0 0;

        @media (max-width: 768px) {
          font-size: 0.65rem;
        }

        @media (max-width: 600px) {
          padding: 0.75rem 0.75rem;
          width: fit-content;
          height: fit-content;
          margin: 0 0.15rem;
          font-size: 0.5rem;
        }
        @media (max-width: 350px) {
          padding: 0.5rem 0.5rem;
          width: fit-content;
          height: fit-content;
          margin: 0 0.05rem 0 0.05rem;
          font-size: 0.5rem;
        }
      }
    }
    .sorting-select {
      height: fit-content;
      padding: 0;
      margin: 0 1rem;

      .MuiSelect-select {
        padding: 0.5rem 1.75rem;
        font-size: $font-size;

        @media (max-width: 768px) {
          padding: 0.5rem 0.5rem;
          font-size: 0.75rem;
        }

        @media (max-width: 600px) {
          padding: 0.5rem 0.5rem;
          font-size: 0.75rem;
        }

        @media (max-width: 350px) {
          padding: 0.25rem 0 0.25rem 0;
          font-size: 0.5rem;
        }
      }

      .MuiSvgIcon-root {
        padding: 0;
        margin: 0;
        right: 0.5rem;

        @media (max-width: 600px) {
          right: 0.15rem;
        }
      }

      .sorting-select-item {
        height: fit-content;
        padding: 0;
        margin: 0;
        font-size: 0.875rem;

        @media (max-width: 600px) {
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          font-size: 0.75rem;
        }
        @media (max-width: 350px) {
          padding-top: 0.15rem;
          padding-bottom: 0.15rem;
          font-size: 0.75rem;
        }
      }
      @media (max-width: 600px) {
        padding: 0.15rem 1rem;
        width: fit-content;
        height: fit-content;
        margin: 0 0.5rem 0 0.5rem;
      }
      @media (max-width: 350px) {
        padding: 0.05rem 0.25rem;
        width: fit-content;
        height: fit-content;
        margin: 0 0.35rem;
      }
    }

    @media (max-width: 768px) {
      font-size: $font-size;
    }

    @media (max-width: 600px) {
      left: $sidebar-width-small;
      width: calc(100% - #{$sidebar-width-small});
      font-size: $font-size;
      // height: $navbar-height-small;
      top: calc(#{$navbar-height} + 3rem);
    }
    &.sidebar-open {
      left: $sidebar-width;
      width: calc(100% - #{$sidebar-width});
    }
    &.sidebar-closed {
      left: $sidebar-width-small;
      width: calc(100% - #{$sidebar-width-small});
    }
  }
  @media (max-width: 768px) {
    padding: 1.25rem;
  }
  @media (max-width: 600px) {
    padding: 1rem;
  }
  @media (max-width: 450px) {
    padding: 0.75rem;
  }
  @media (max-width: 350px) {
    padding: 0.5rem;
  }
}
