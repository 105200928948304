.listing-page {
  width: 100%;
  margin: 0;
  margin-bottom: 5rem;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .app-bar {
    color: var(--text-color);
    background-color: var(--second-navbar-bg);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: calc(#{$navbar-height} + 3rem);
    padding: 0;
    margin: 0;
    font-size: $font-size-large;
    transition: left 0.4s ease-in-out, width 0.4s ease-in-out;
    height: $navbar-height;
    z-index: 9;

    @media (max-width: 768px) {
      font-size: $font-size;
    }

    @media (max-width: 600px) {
      left: $sidebar-width-small;
      width: calc(100% - #{$sidebar-width-small});
      font-size: $font-size-small;
    }
    &.sidebar-open {
      left: $sidebar-width;
      width: calc(100% - #{$sidebar-width});
    }
    &.sidebar-closed {
      left: $sidebar-width-small;
      width: calc(100% - #{$sidebar-width-small});
    }
  }

  &-body {
    width: 100%;
    max-width: 55rem;
    height: calc(100% - #{$navbar-height});
    margin: 0;
    padding: 0;
    margin-top: calc(#{$navbar-height} + 1rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.listing-card {
  position: relative;
  width: 100%;
  max-width: 55rem;
  border: 0.5px solid #666;
  padding-bottom: 20px;
  overflow: auto;

  .coming-soon-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(
      255,
      165,
      0,
      0.75
    ); /* Orange with some transparency */
    color: white;
    text-align: center;
    padding: 5px 0;
    font-size: 16px;
    font-weight: bold;
    z-index: 10;
  }

  &.margin-bottom {
    margin-bottom: 1rem;
  }

  .aspect-ratio-16-9 {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    overflow: hidden;
  }

  .aspect-ratio-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .listing-content-image {
    max-width: 100%;
    height: auto;
  }

  .listing-card-header {
    font-size: $font-size;
    .card-header-title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      font-size: $font-size;
    }

    @media (max-width: 600px) {
      font-size: $font-size-small;
    }

    @media (max-width: 350px) {
      font-size: $font-size-small;
    }

    &-date {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: var(--no-sidebar-bg);
      padding: 0.25rem 0.75rem;
      border-radius: 5px;
      border: 1px solid var(--nav-btn-link-text-color);

      &-day {
        font-size: $font-size-large;
        @media (max-width: 600px) {
          font-size: $font-size;
        }
        @media (max-width: 450px) {
          font-size: $font-size-small;
        }
        @media (max-width: 350px) {
          font-size: $font-size-smallest;
        }
      }
      &-month {
        font-size: $font-size;
        @media (max-width: 600px) {
          font-size: $font-size-small;
        }
        @media (max-width: 450px) {
          font-size: $font-size-smallest;
        }
      }

      &-local {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        &-event-type-item {
          padding: 0.25rem 0.75rem;
          margin: 0.25rem 0.75rem;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          @media (max-width: 600px) {
            padding: 0.15rem 0.5rem;
            margin: 0.15rem 0.5rem;
          }
          @media (max-width: 450px) {
            padding: 0.05rem 0.35rem;
            margin: 0.05rem 0.35rem;
          }
        }
        &-small {
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          font-size: $font-size;
          @media (max-width: 600px) {
            font-size: $font-size-small;
          }
          @media (max-width: 450px) {
            font-size: $font-size-smallest;
          }
        }
      }
    }
    .avatar {
      cursor: pointer;
    }

    .avatar-name {
      cursor: pointer;
      text-decoration: none;
      color: var(--nav-btn-link-color);
      font-size: $font-size;
      @media (max-width: 600px) {
        font-size: $font-size;
      }

      @media (max-width: 350px) {
        font-size: $font-size-small;
      }
    }

    .category-text {
      padding-left: 0.5rem;
      font-size: $post-card-header-font-size;
      @media (max-width: 600px) {
        padding-left: 0.35rem;
        font-size: $post-card-header-font-size;
      }

      @media (max-width: 350px) {
        padding-left: 0.25rem;
        font-size: $post-card-header-font-size-small;
      }
    }

    .date-text {
      font-size: $post-card-header-font-size;
      @media (max-width: 600px) {
        font-size: $post-card-header-font-size;
      }

      @media (max-width: 350px) {
        font-size: $post-card-header-font-size-small;
      }
    }
  }

  .listing-card-title-wrap {
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: space-between;

    .listing-card-title {
      width: 100%;
      font-size: $post-card-title-font-size-large;
      font-weight: 800;
      margin-bottom: 0.75rem;
      padding-bottom: 0rem;
      flex: 75%;

      @media (max-width: 600px) {
        font-size: $post-card-title-font-size;
        font-weight: 700;
      }

      @media (max-width: 350px) {
        font-weight: 450;
        font-size: $post-card-title-font-size-small;
      }
    }

    .listing-card-view-badge {
      flex: 25%;
    }
  }
  .custom-card-content {
    padding-top: 0;
    margin-top: 0;

    .likes-block {
      .likes-block-avatars {
        .likes-avatars {
          display: flex;
          flex-direction: row;
          padding: 0.5rem 0.5rem;
        }
      }
    }
  }

  .listing-card-address {
    font-size: $font-size-largest;
    font-weight: 700;
  }
  .listing-card-listPrice {
    font-size: $font-size-large;
    font-weight: 700;
    color: green;
  }
  .listing-card-listing {
    &-title {
      font-size: $post-card-title-font-size;
      font-weight: 600;
    }
  }

  .listing-custom-card-content {
    padding: 10px;
    margin-top: 0;
    border: none;
    background-color: var(--listing-custom-card-bg);

    .likes-block {
      .likes-block-avatars {
        .likes-avatars {
          display: flex;
          flex-direction: row;
          padding: 0.5rem 0.5rem;
        }
      }
    }
  }

  .listing-card-tier-label {
    margin: 0;
    padding: 0.5rem 1rem;
    font-size: 0.75rem;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: var(--post-card-tier-office-label-color);
    background-color: var(--post-card-tier-office-label-bg);
  }
  .listing-card-new-listing-label {
    margin: 0;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    width: 100%;
    text-align: center;
    font-weight: bold;
    color: var(--post-card-new-post-label-color);
    background-color: var(--post-card-new-post-label-bg);
  }
}

.listing-category-body {
  margin: 0;
  padding: 0;
  width: 100%;

  .listing-tour-page-title {
    font-size: $font-size-large;
    color: red;
    margin: 0 0 1rem 2rem;
    @media (max-width: 768px) {
      font-size: $font-size;
      margin: 0 0 1rem 1rem;
    }
    @media (max-width: 768px) {
      font-size: $font-size-small;
      margin: 0 0 0.5rem 0;
    }
  }
}

.listing-tour-tab-body {
  padding: 0;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .listing-tour-tab-body-header {
    padding: 1rem 2rem;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .listing-tour-tab-body-data-grids {
    padding: 1rem 0.5rem 0 0.5rem;
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    &-item {
      max-height: 50rem;
      width: 100%;
      max-width: 50%;
      &.past-tour {
        max-width: 100%;
      }
    }
  }
}

.agents-listings-page {
  padding: 0;
  margin: 0;
  height: calc(100% - #{$navbar-height});
  width: 100%;
}

.sticky-tabs {
  position: sticky;
  top: 0;
  z-index: 1000; /* Ensure the tabs stay above other elements */
  background-color: white; /* Set a background to avoid content behind the tabs showing through */
}
